import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.modalBg,
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      fontFamily: "Philosopher",
      fontWeight: 900,
      gap: 8,
    },

    "& .MuiDialog-paper": {
      maxWidth: 960,
    },
  },

  modalContent: {
    position: "relative",
    minWidth: 480,
    maxWidth: "960px!important",
  },

  contentBody: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: "32px 48px",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px",
    },
  },

  closeIconBody: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 25,
    top: 25,
    "&>button": {
      padding: 0,
      minWidth: "auto",
      "&>svg": {
        color: theme.palette.text.secondary,
        cursor: "pointer",
        transition: ".3s",
        fontSize: "1.5rem",
        "&:hover": {
          color: theme.palette.text.primary,
          transition: ".3s",
        },
      },
    },
  },
  stakingText: {
    fontWeight: 800,
    display: "flex",
    fontSize: "24px",
    justifyContent: "center",
    marginBottom: 16,
  },

  buttonSection: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
    "&>button": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.defaultButton,
      borderRadius: "8px",
      padding: "8px 16px",
      textTransform: "none",
      minWidth: "150px",
      fontFamily: "Philosopher",
    },

    "& span": {
      color: "white",
    },
  },

  infoBoxList: {
    display: "grid",
    gridTemplateColumns: "2fr 3fr 2fr",
    backgroundColor: "#0d0f11",
    borderRadius: 16,
  },

  infoBox: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    fontSize: 20,
    borderRight: "1px solid #282828",

    ":last-of-type": {
      borderRight: "none",
    },
  },

  infoBoxLabel: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },

  infoBoxValue: {
    fontSize: 18,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
  },

  stakingList: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    marginTop: 16,
    width: "100%",
  },

  listHeader: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
    gap: 16,
  },

  listHeaderItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 700,
  },

  listBody: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },

  listItem: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
    gap: 16,

    borderBottom: "1px solid",
    borderColor: theme.palette.background.secondaryBorder,
    padding: "8px 0",
    "&:last-of-type": {
      borderBottom: "none",
    },
  },

  listItemValue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 700,
    whiteSpace: "nowrap",
  },

  endStakingButton: {
    whiteSpace: "nowrap",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.blackBorder,
    borderRadius: "8px",
    padding: "0px 8px",
    textTransform: "none",
    minWidth: "40px",
    fontFamily: "Philosopher",
  },
}));

export default styles;
