import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useCurrentTime from "../../hook/useCurrentTime";
import useWallet from "../../hook/useWallet";

import { convertDateTime, toastSuccess, toUSDFormat } from "../../utils/utils";

import {
  FAMILY_BANK_WITHDRAW_COOLDOWN,
  FAMILY_BANK_YIELD_PERCENT,
} from "../../constants/const";
import { Messages } from "../../constants/messages";
import { getLastWithdrawAt } from "../../helper/contractFunctions/MafiaFamilyBank";
import { getUserSlotCount } from "../../helper/contractFunctions/MafiaMap";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import {
  getFamilyDetailByName,
  withdrawBankStakingAction,
} from "../../reducers/family.slice";
import { getFamilyStakingInfos } from "../../reducers/profile.slice";

import Logo from "../Logo";

import bulletIcon from "../../assets/imgs/family/bullets.png";
import moneyIcon from "../../assets/imgs/family/money.png";

import useStyles from "./index.styles";

const WITHDRAWABLE_MAFIA_PER_SLOT = 20000;

interface FamilyEndStakingPopupProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FamilyEndStakingPopup: React.FC<FamilyEndStakingPopupProps> = ({
  openModal,
  setOpenModal,
}) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { account } = useWallet();
  const currentTime = useCurrentTime();

  const { familyStakingInfos } = useAppSelector((state) => state.profile);

  const { familyDetail, familyList } = useAppSelector((state) => state.family);

  const [confirmChecked, setIsChecked] = useState(false);
  const [slotsOwned, setSlotsOwned] = useState(0);
  const [lastWithdrawAt, setLastWithdrawAt] = useState(0);

  const [yieldPercent, setYieldPercent] = useState(1);

  const { isWithdrawingStaking } = useAppSelector((state) => state.family);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleWithdrawStaking = (stakingId: number) => {
    if (!confirmChecked) return;

    dispatchTxAction(
      dispatch,
      withdrawBankStakingAction({
        account,
        stakingId,
      }),
      () => {
        toastSuccess(Messages.FAMILY.STAKE.WITHDRAW_SUCCESS);
        setTimeout(() => {
          handleClose();

          dispatch(getFamilyDetailByName(familyDetail.familyInfo?.name || ""));
          dispatch(getFamilyStakingInfos(account));
        }, 3000);
      }
    );
  };

  const handleConfirmCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (!account) return;

    const fetchBalance = async () => {
      const slotCount = await getUserSlotCount(account);

      const lastWithdrawAt = await getLastWithdrawAt(account);

      setSlotsOwned(slotCount);
      setLastWithdrawAt(lastWithdrawAt);
    };

    fetchBalance();

    dispatch(getFamilyStakingInfos(account));
  }, [account, dispatch]);

  useEffect(() => {
    const intervalCount = 10;

    const intervalId = setInterval(() => {
      setYieldPercent(
        (prevBalance) =>
          prevBalance +
          (prevBalance * FAMILY_BANK_YIELD_PERCENT) /
            100 /
            86400 /
            intervalCount
      );
    }, 1000 / intervalCount);

    return () => {
      clearInterval(intervalId);
    };
  }, [familyDetail.bankInfo?.bank]);

  const bulletAmount = useMemo(() => {
    return Math.floor(Math.random() * 1000);
  }, []);

  const remainTime =
    FAMILY_BANK_WITHDRAW_COOLDOWN - (currentTime - lastWithdrawAt) > 0
      ? convertDateTime(
          FAMILY_BANK_WITHDRAW_COOLDOWN - (currentTime - lastWithdrawAt)
        )
      : "NOW";

  return (
    <Dialog open={openModal} onClose={handleClose} className={classes.body}>
      <Box className={classes.modalContent}>
        <Box className={classes.closeIconBody}>
          <Button onClick={handleClose}>
            <CloseIcon htmlColor="gray" />
          </Button>
        </Box>

        <Box className={classes.contentBody}>
          <Box className={classes.stakingText}>Family End Staking</Box>

          <Box className={classes.infoBoxList}>
            <Box className={classes.infoBox}>
              <Box className={classes.infoBoxLabel}>Tiles owned:</Box>

              <Box className={classes.infoBoxValue}>{slotsOwned}</Box>
            </Box>
            <Box className={classes.infoBox}>
              <Box className={classes.infoBoxLabel}>Next unstake:</Box>

              <Box className={classes.infoBoxValue}>
                {lastWithdrawAt === 0 ? "NOW" : remainTime}
              </Box>
            </Box>

            <Box className={classes.infoBox}>
              <Box className={classes.infoBoxLabel}>Unstake amount:</Box>

              <Box className={classes.infoBoxValue}>
                {slotsOwned === 0
                  ? toUSDFormat(5000)
                  : toUSDFormat(slotsOwned * WITHDRAWABLE_MAFIA_PER_SLOT)}
              </Box>
            </Box>
          </Box>

          <Box className={classes.stakingList}>
            <Box className={classes.listHeader}>
              <Box className={classes.listHeaderItem}>Family</Box>
              <Box className={classes.listHeaderItem}>
                <Logo
                  sx={{
                    width: "16px",
                    height: "16px",
                    marginRight: "4px",
                  }}
                />
                stMAFIA
              </Box>
              <Box className={classes.listHeaderItem}>Duration</Box>
              <Box className={classes.listHeaderItem}>
                <img
                  src={moneyIcon}
                  style={{ width: "16px", height: "16px", marginRight: "4px" }}
                  alt="money"
                />
                Yield
              </Box>
              <Box className={classes.listHeaderItem}>
                <img
                  src={bulletIcon}
                  style={{ width: "16px", height: "16px", marginRight: "4px" }}
                  alt="bullet"
                />
                Bullets
              </Box>
              <Box className={classes.listHeaderItem}>Action</Box>
            </Box>
            <Box className={classes.listBody}>
              {familyStakingInfos.map((stakingInfo, index) => (
                <Box className={classes.listItem} key={index}>
                  <Box className={classes.listItemValue}>
                    {
                      familyList.find((f) => f.id === stakingInfo.familyId)
                        ?.name
                    }
                  </Box>
                  <Box className={classes.listItemValue}>
                    {toUSDFormat(stakingInfo.amount)}
                  </Box>
                  <Box
                    className={classes.listItemValue}
                    sx={{
                      minWidth: 120,
                      fontSize: "14px!important",
                    }}
                  >
                    {convertDateTime(currentTime - stakingInfo.timestamp)}
                  </Box>
                  <Box className={classes.listItemValue}>
                    {(stakingInfo.yieldAmount * yieldPercent).toFixed(1)}
                  </Box>
                  <Box
                    className={classes.listItemValue}
                    sx={{ filter: "blur(5px)" }}
                  >
                    {bulletAmount}
                  </Box>
                  <Box className={classes.listItemValue}>
                    <Tooltip
                      title={
                        remainTime === "NOW" ? "" : "You have to wait cooldown."
                      }
                    >
                      <Button
                        className={classes.endStakingButton}
                        onClick={() => {
                          remainTime === "NOW" &&
                            handleWithdrawStaking(stakingInfo.id);
                        }}
                        disabled={isWithdrawingStaking || !confirmChecked}
                      >
                        Unstake
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmChecked}
                  onChange={handleConfirmCheckChange}
                  color="primary"
                />
              }
              label={
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "Philosopher",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  I fully understand, how family endstaking works.
                </Typography>
              }
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FamilyEndStakingPopup;
