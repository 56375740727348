import { ethers } from "ethers";
import { getProvider, getWeb3 } from ".";
import MafiaGameBankAbi from "../../abis/MafiaGameBank.json";
import config from "../../config/config";

export const getMafiaGameBankContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.gamebankAddress;
  const MafiaGameBankContract = new web3.eth.Contract(
    MafiaGameBankAbi,
    contractAddress
  );
  return MafiaGameBankContract;
};

export const getMafiaGameBankBalance = async (address: string) => {
  const MafiaGameBankContract = getMafiaGameBankContract();
  const balance: number = await MafiaGameBankContract.methods
    .balanceOf(address)
    .call({ from: address });
  const decimal: string = await MafiaGameBankContract.methods.decimals().call();
  return ethers.utils.formatUnits(balance, decimal);
};

export const getMafiaGameBankBalanceWithSig = async (
  address: string,
  signMsg: string,
  signature: string
) => {
  const MafiaGameBankContract = getMafiaGameBankContract(true);

  const balance: number = await MafiaGameBankContract.methods
    .balanceOfWithSignMsg(address, JSON.stringify(signMsg), signature)
    .call();

  const decimal: string = await MafiaGameBankContract.methods.decimals().call();
  return ethers.utils.formatUnits(balance, decimal);
};

export const approveGameCash = async (
  tokenAddress: string,
  amount: number,
  to: string,
  account: string
) => {
  const MafiaGameBankContract = getMafiaGameBankContract(true);
  const decimals = Number(
    await MafiaGameBankContract.methods.decimals().call()
  );
  const data = await MafiaGameBankContract.methods
    .approveInGameCurrency(
      to,
      ethers.utils
        .parseUnits(Number(amount).toFixed(decimals), decimals)
        .toString()
    )
    .send({ from: account });

  return data;
};

export const getTotalSupply = async () => {
  const MafiaGameBankContract = getMafiaGameBankContract();
  const decimals = Number(
    await MafiaGameBankContract.methods.decimals().call()
  );
  const data = await MafiaGameBankContract.methods.getTotalSupply().call();
  return Number(
    ethers.utils.formatUnits(ethers.BigNumber.from(data), decimals.toString())
  );
};
