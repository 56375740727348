import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  container: {
    display: "flex",
    gap: 32,
    gridTemplateColumns: "1fr 1fr",
    maxWidth: 1440,
    margin: "16px auto",
    padding: "0 16px",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  body: {
    marginTop: -64,
  },
}));

export default styles;
