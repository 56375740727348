import { getProvider, getWeb3 } from ".";
import config from "../../config/config";
import MafiaMapAbi from "../../abis/MafiaMap.json";

export const getMafiaMapContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.mapAddress;
  const MafiaMapContract = new web3.eth.Contract(MafiaMapAbi, contractAddress);
  return MafiaMapContract;
};

export const getUserSlotCount = async (account: string) => {
  const MafiaMapContract = getMafiaMapContract();

  const slotCount = await MafiaMapContract.methods
    .userSlotCount(account)
    .call({ from: account });

  return Number(slotCount);
};
