import { ArrowDropUp } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, Pagination, Skeleton, Tooltip } from "@mui/material";
import millify from "millify";
import { useEffect, useMemo, useState } from "react";

import { useAppSelector } from "../../app/hooks";
import vaultIcon from "../../assets/imgs/family/security.png";
import vaultDisabledIcon from "../../assets/imgs/family/security_disabled.png";
import vaultConditional from "../../assets/imgs/family/vault_conditional.png";
import goldenPlusIcon from "../../assets/imgs/profile/GoldenPlus.png";

import config, { CHAIN } from "../../config/config";
import { City, VaultDistributionRatio } from "../../constants/const";
import { ChainType } from "../../constants/enum/enum";
import { hasFamily } from "../../helper/contractFunctions/MafiaFamily";
import useVaultBalance from "../../hook/useVaultBalance";
import useWallet from "../../hook/useWallet";
import { FamilyInfo } from "../../types/FamilyInfo";
import { toUSDFormat } from "../../utils/utils";

import FamilyEndStakingPopup from "../FamilyEndStakingPopup";
import Logo from "../Logo";
import NativeIcon from "../NativeIcon";

import useStyles from "./index.styles";

const FamilyListItem = ({
  vaultAmount,
  family,
}: {
  vaultAmount: number;
  family: FamilyInfo;
}) => {
  const { classes } = useStyles();

  const handleGotoDetailPage = (link: string) => {
    window.open(link, "_self");
  };

  const handleProfileClick = (profileLink: string) => {
    window.open(profileLink, "_self");
  };

  return (
    <Box
      className={classes.listItem}
      onClick={() => {
        handleGotoDetailPage(`/family/${family.name}`);
      }}
    >
      <Box>#{family.rank}</Box>
      <Box>{toUSDFormat(family.worth || 0, 0)}</Box>
      <Box className={classes.familyName}>
        {family.logo ? (
          <Box
            component="img"
            className={classes.familyLogo}
            src={family.logo}
          ></Box>
        ) : (
          <Logo classNames={classes.familyLogo} />
        )}
        {family.premium === 1 ? (
          <>
            <Box className={classes.goldenText}>{family.name}</Box>
            <Box
              component="img"
              className={classes.goldenPlus}
              src={goldenPlusIcon}
            ></Box>
          </>
        ) : (
          family.name
        )}
      </Box>
      <Box
        onClick={(event) => {
          event.stopPropagation(); // Prevent parent onClick from firing
          handleProfileClick(`/profile/${family.don}`);
        }}
        className={classes.link}
      >
        {family.don}
      </Box>
      <Box className={classes.familyAllegiance}>
        {family.allegiance ? (
          <>
            {family.allegiance?.symbol && (
              <Box component="img" src={family.allegiance?.symbol}></Box>
            )}
            {family.allegiance?.name}
          </>
        ) : (
          "-"
        )}
      </Box>
      <Box>{family.members}</Box>
      <Box>{City[family.city || 0]}</Box>

      <Box>
        {millify(family.mafia || 0, {
          precision: 1,
        })}
      </Box>

      <Box>
        $
        {millify(family.bank || 0, {
          precision: 1,
        })}
      </Box>

      <Box>
        {millify(family.stMafia || 0, {
          precision: 1,
        })}
      </Box>

      <Box className={classes.vaultIcon}>
        {family.rank && family.rank <= 5 ? (
          <Tooltip
            title={`${
              VaultDistributionRatio[family.rank - 1]
            }% Payout from vault`}
          >
            <Box component="img" src={vaultIcon}></Box>
          </Tooltip>
        ) : family.rank && family.rank <= 8 ? (
          <Tooltip title="5% Conditional payout">
            <Box component="img" src={vaultConditional}></Box>
          </Tooltip>
        ) : (
          <Tooltip title="Not qualified">
            <Box component="img" src={vaultDisabledIcon}></Box>
          </Tooltip>
        )}
      </Box>

      <Box
        sx={{
          fontSize: 14,
          color: "white",
        }}
      >
        <NativeIcon
          sx={{
            width: 12,
            height: 12,
          }}
        />
        {millify(vaultAmount * (family.vaultPercent || 0), {
          precision: 1,
        })}

        {config.chainSymbol}
      </Box>
    </Box>
  );
};

const FamilyListItemSkeleton = () => {
  const { classes } = useStyles();

  // TODO: Update rendering skeleton logic

  return (
    <Box className={classes.listItem}>
      <Skeleton
        height={32}
        width={40}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />
      <Skeleton
        height={32}
        width={80}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />
      <Box className={classes.familyName}>
        <Skeleton
          height={32}
          width={24}
          variant="rounded"
          animation="wave"
          sx={{ bgcolor: "#ffffff1a" }}
        />

        <Skeleton
          height={32}
          width={40}
          variant="rounded"
          animation="wave"
          sx={{ bgcolor: "#ffffff1a" }}
        />
      </Box>
      <Skeleton
        height={32}
        width={80}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />
      <Skeleton
        height={32}
        width={40}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />

      <Skeleton
        height={32}
        width={60}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />

      <Skeleton
        height={32}
        width={80}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />
      <Skeleton
        height={32}
        width={60}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />
      <Skeleton
        height={32}
        width={60}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />
      <Skeleton
        height={32}
        width={60}
        variant="rounded"
        animation="wave"
        sx={{ bgcolor: "#ffffff1a" }}
      />
      <Box className={classes.vaultIcon}>
        <Skeleton
          height={24}
          width={24}
          variant="rounded"
          animation="wave"
          sx={{ bgcolor: "#ffffff1a" }}
        />
      </Box>
    </Box>
  );
};

const FamilyList = () => {
  const { classes } = useStyles();
  const { account, connectWallet } = useWallet();
  const vaultAmount = useVaultBalance();

  const { familyList, isLoadingFamilyList } = useAppSelector(
    (state) => state.family
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [sortByWorth, setSortByWorth] = useState(false);
  const [isInFamily, setIsInFamily] = useState(false);
  const [openEndStakingModal, setOpenEndStakingModal] = useState(false);

  const familyPerPage = 20;

  const sortedFamilies = useMemo(() => {
    // Filter the familyList based on the condition
    let result = [...familyList].filter((family) => {
      return family.name !== "Chinese Triads" || CHAIN !== ChainType.BNB;
    });

    // Reverse the list if sortByWorth changes
    if (sortByWorth) {
      result.reverse();
    }

    return result;
  }, [familyList, sortByWorth]); // Add dependencies for familyList, CHAIN, and sortByWorth

  const handleReverseSortOrder = () => {
    setSortByWorth((previousState) => !previousState);
  };

  const handleOnCreateFamily = () => {
    if (!account) {
      connectWallet();
    } else {
      window.open("/familycreate", "_self");
    }
  };

  const handleOpenEndStakingModal = () => {
    setOpenEndStakingModal(true);
  };

  useEffect(() => {
    if (!account) return;

    const fetchData = async () => {
      const isInFamily: boolean = await hasFamily(account);

      setIsInFamily(isInFamily);
    };

    fetchData();
  }, [account]);

  return (
    <Box className={classes.container}>
      <Box className={classes.pageHeader}>
        <Box>Families</Box>
        <Button onClick={handleOpenEndStakingModal}>End Staking</Button>
        {!isInFamily && (
          <Button onClick={handleOnCreateFamily}>Create Family</Button>
        )}
      </Box>

      <Box className={classes.familyList}>
        <Box className={classes.listHeader}>
          <Box>Rank</Box>
          <Box
            onClick={handleReverseSortOrder}
            className={classes.clickingOrder}
          >
            Worth {sortByWorth ? <ArrowDropDownIcon /> : <ArrowDropUp />}
          </Box>
          <Box>Name</Box>
          <Box>Don</Box>
          <Box>Allegiance</Box>
          <Box>Members</Box>
          <Box>City</Box>
          <Box>Mafia</Box>
          <Box>Bank</Box>
          <Box>stMafia</Box>
          <Box className={classes.vaultIcon}>Vault access</Box>
          <Box className={classes.vaultIcon}>Total {config.chainSymbol}</Box>
        </Box>
        {isLoadingFamilyList
          ? [...new Array(familyPerPage)].map((value, index) => {
              return <FamilyListItemSkeleton key={index} />;
            })
          : sortedFamilies
              .slice(
                (currentPage - 1) * familyPerPage,
                currentPage * familyPerPage
              )
              .map((family, index) => {
                return family.name === "Chinese Triads" &&
                  CHAIN === ChainType.BNB ? (
                  ""
                ) : (
                  <FamilyListItem
                    vaultAmount={vaultAmount}
                    family={family}
                    key={index}
                  />
                );
              })}
      </Box>

      <Pagination
        page={currentPage}
        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
          setCurrentPage(value);
        }}
        count={Math.ceil(sortedFamilies.length / familyPerPage)}
        variant="outlined"
        size="large"
        classes={{ root: classes.pagination }}
      />

      <FamilyEndStakingPopup
        openModal={openEndStakingModal}
        setOpenModal={setOpenEndStakingModal}
      />
    </Box>
  );
};

export default FamilyList;
