import { Box, Button, Divider, Pagination, Tooltip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RefreshIcon from "@mui/icons-material/Refresh";

import useStyles from "./index.styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { StatType } from "../../constants/enum/deposit";
import clsx from "clsx";
import useMafiaPrice from "../../hook/useMafiaPrice";

import { getUTCTimeFromTimestamp, toUSDFormat } from "../../utils/utils";
import Logo from "../Logo";
import React, { useEffect, useState } from "react";
import useWallet from "../../hook/useWallet";
import { DepositItem } from "../../types/DepositItem";
import { getWithdrawStat } from "../../reducers/exchange.slice";
import { Link } from "react-router-dom";

import moneyIcon from "../../assets/imgs/exchange/money.png";
import hatIcon from "../../assets/imgs/exchange/hat.png";
import networkIcon from "../../assets/imgs/exchange/network.png";
import config from "../../config/config";

const ActionButton = ({
  item,
  handleCancelLiquidity,
  handleWithdrawMafia,
}: {
  item: DepositItem;
  handleCancelLiquidity: (itemId: number) => void;
  handleWithdrawMafia: (itemId: number) => void;
}) => {
  const { classes } = useStyles();
  const { chartHistory } = useAppSelector((state) => state.exchange);

  return item.active ? (
    <>
      <Button
        className={classes.withdrawButton}
        variant="contained"
        onClick={() =>
          item.cashAmount > 0
            ? handleWithdrawMafia(item.id)
            : handleCancelLiquidity(item.id)
        }
      >
        {item.cashAmount > 0 ? "Withdraw" : "Complete & Withdraw"}
      </Button>
      {item.cashAmount > 0 && (
        <Button
          className={classes.cancelButton}
          variant="contained"
          onClick={() => handleCancelLiquidity(item.id)}
        >
          Cancel
        </Button>
      )}
    </>
  ) : !item.active ? (
    <Button className={classes.cancelButton} variant="contained" disabled>
      Completed
    </Button>
  ) : null;
};

const TableHeader = ({ statType }: { statType: StatType }) => {
  const { classes } = useStyles();

  return statType !== StatType.ViewAllBuyOrders ? (
    <Box
      className={classes.tableHeader}
      sx={{
        gridTemplateColumns: "3fr 1fr 1fr 3fr 2fr 3fr",
      }}
    >
      <Box>Amount</Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        Position
        <ArrowDropDownIcon />
      </Box>
      <Box>Ratio</Box>
      <Box>Remaining</Box>
      <Box>Available</Box>
      <Box>User & Action</Box>
    </Box>
  ) : (
    <Box
      className={classes.tableHeader}
      sx={{
        gridTemplateColumns: "2fr 2fr 3fr 1fr 2fr 2fr",
      }}
    >
      <Box>Buy type</Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Box component={"img"} src={moneyIcon} sx={{ width: "24px" }}></Box>
        Amount
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        Time
        <ArrowDropDownIcon />
      </Box>
      <Box>Ratio</Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Logo sx={{ width: "24px" }}></Logo>Paid
      </Box>
      <Box>Total USD</Box>
    </Box>
  );
};

const WithdrawStat = ({
  statType,
  setStatType,
  page,
  perPage,
  setPage,
  handleCancelLiquidity,
  handleWithdrawMafia,
}: {
  statType: StatType;
  setStatType: (statType: StatType) => void;
  page: number;
  perPage: number;
  setPage: (page: number) => void;
  handleCancelLiquidity: (id: number) => void;
  handleWithdrawMafia: (id: number) => void;
}) => {
  const { classes } = useStyles();
  const { withdrawStat, activeLPs, countStats, chartHistory } = useAppSelector(
    (state) => state.exchange
  );
  const { profileNames } = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();

  const [maximumLP, setMaximumLP] = useState(1);

  const mafiaPrice = useMafiaPrice();
  const { account } = useWallet();

  useEffect(() => {
    let max = 1;
    withdrawStat.forEach((item, index) => {
      if (item.cashTotalAmount > max) {
        max = item.cashTotalAmount;
      }
    });
    setMaximumLP(max);
  }, [withdrawStat]);

  return (
    <Box className={classes.positionStat}>
      <Box className={classes.positionTab}>
        <Box
          className={clsx(
            classes.tabItem,
            statType === StatType.AllPosition ? classes.tabActive : null
          )}
          onClick={() => {
            setStatType(StatType.AllPosition);
          }}
        >
          All positions
        </Box>
        <Box
          className={clsx(
            classes.tabItem,
            statType === StatType.ViewAllBuyOrders ? classes.tabActive : null
          )}
          onClick={() => setStatType(StatType.ViewAllBuyOrders)}
        >
          View all buy orders
        </Box>
        <Box
          className={clsx(
            classes.tabItem,
            statType === StatType.MyActivePos ? classes.tabActive : null
          )}
          onClick={() => {
            setStatType(StatType.MyActivePos);
          }}
        >
          My liquidity positions
        </Box>
        <Box
          className={clsx(
            classes.tabItem,
            statType === StatType.MyCompletedPos ? classes.tabActive : null
          )}
          onClick={() => {
            setStatType(StatType.MyCompletedPos);
          }}
        >
          My completed positions
        </Box>

        <Tooltip title="Refresh">
          <RefreshIcon
            className={classes.refreshIcon}
            onClick={() =>
              dispatch(
                getWithdrawStat({
                  account,
                  statType,
                  pageIndex: page - 1,
                  perPage,
                })
              )
            }
          />
        </Tooltip>
      </Box>
      <Box className={classes.tableContainer}>
        <Box className={classes.statTable}>
          <TableHeader statType={statType} />

          {statType !== StatType.ViewAllBuyOrders
            ? withdrawStat.map((item, index) => (
                <Box key={index}>
                  <Box
                    className={classes.tableBody}
                    sx={{
                      gridTemplateColumns: "3fr 1fr 1fr 3fr 2fr 3fr",
                    }}
                  >
                    <Box className={classes.amountCell}>
                      <Box
                        className={classes.cashBgColor}
                        sx={{
                          minWidth: `${(item.cashAmount / maximumLP) * 100}%`,
                          opacity: 1,
                          display: item.cashAmount === 0 ? "none" : "absolute",
                        }}
                      ></Box>
                      <Box
                        className={classes.cashBgColor}
                        sx={{
                          minWidth: `${
                            (item.cashTotalAmount / maximumLP) * 100
                          }%`,
                          opacity: 0.3,
                        }}
                      ></Box>
                      <Box
                        component={"img"}
                        src={moneyIcon}
                        className={classes.moneyIcon}
                      />
                      <Box zIndex={1} display={"flex"} gap={1}>
                        {toUSDFormat(item.cashAmount)}
                        <Box sx={{ opacity: 0.4 }}>
                          (
                          {toUSDFormat(
                            (item.cashAmount / item.cashTotalAmount) * 100
                          )}
                          %)
                        </Box>
                      </Box>
                    </Box>
                    <Box zIndex={1}>
                      {statType === StatType.MyCompletedPos ||
                      (statType === StatType.MyActivePos &&
                        item.cashAmount === 0)
                        ? "-"
                        : activeLPs.filter(
                            (lp) => lp.cashPerMafia > item.cashPerMafia
                          ).length + 1}
                    </Box>
                    <Box zIndex={1}>1/{item.cashPerMafia}</Box>
                    <Box className={classes.mafiaCell}>
                      <Logo sx={{ width: "20px" }} />
                      <Box component={"span"}>
                        {toUSDFormat(item.cashAmount / item.cashPerMafia)} MAFIA{" "}
                        <Box component={"span"} sx={{ opacity: 0.4 }}></Box>{" "}
                        <Box component={"span"} sx={{ opacity: "0.4" }}>
                          ($
                          {toUSDFormat(
                            (item.cashAmount / item.cashPerMafia) * mafiaPrice
                          )}
                          )
                        </Box>
                      </Box>
                    </Box>
                    <Box zIndex={1}>
                      {toUSDFormat(item.mafiaEarned - item.mafiaWithdrawn)}{" "}
                      MAFIA
                      <Box component={"span"} sx={{ opacity: 0.4 }}>
                        ($
                        {toUSDFormat(
                          (item.mafiaEarned - item.mafiaWithdrawn) * mafiaPrice
                        )}
                        )
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        justifyContent: "flex-start",
                        zIndex: 1,
                      }}
                    >
                      {account === item.provider.toLowerCase() ? (
                        <ActionButton
                          item={item}
                          handleCancelLiquidity={handleCancelLiquidity}
                          handleWithdrawMafia={handleWithdrawMafia}
                        />
                      ) : (
                        <Link
                          className={classes.profileNameLink}
                          to={`/profile/${
                            profileNames.find(
                              (info) =>
                                info.address?.toLowerCase() ===
                                item.provider.toLowerCase()
                            )?.name
                          }`}
                        >
                          {
                            profileNames.find(
                              (info) =>
                                info.address?.toLowerCase() ===
                                item.provider.toLowerCase()
                            )?.name
                          }
                        </Link>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))
            : chartHistory
                .map(
                  (item, index) => chartHistory[chartHistory.length - 1 - index]
                )
                .slice((page - 1) * perPage, page * perPage)
                .map((item, index) => (
                  <Box key={index}>
                    <Box
                      className={classes.tableBody}
                      sx={{
                        gridTemplateColumns: "2fr 2fr 3fr 1fr 2fr 2fr",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component={"img"}
                          sx={{ width: "24px" }}
                          src={
                            item.user === config.vaultSplitterAddress
                              ? networkIcon
                              : hatIcon
                          }
                        />
                        <Box component={"span"}>
                          {item.user === config.vaultSplitterAddress
                            ? "Protocol"
                            : "User"}
                        </Box>
                      </Box>
                      <Box zIndex={1}>{toUSDFormat(item.cashReceived, 0)}</Box>
                      <Box zIndex={1}>
                        {getUTCTimeFromTimestamp(item.timestamp)}
                      </Box>
                      <Box className={classes.mafiaCell}>
                        1/{toUSDFormat(item.avgRatio, 3)}
                      </Box>
                      <Box zIndex={1}>{item.mafiaSpent} MAFIA</Box>
                      <Box>
                        ${toUSDFormat(item.priceUSD * item.cashReceived, 2)}
                      </Box>
                    </Box>
                  </Box>
                ))}

          <Box className={classes.paginationBox}>
            <Pagination
              count={
                statType !== StatType.ViewAllBuyOrders
                  ? Math.ceil(countStats / perPage)
                  : Math.ceil(chartHistory.length / perPage)
              }
              page={page}
              onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                setPage(value)
              }
              className={classes.pagination}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WithdrawStat;
