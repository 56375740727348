import { ethers } from "ethers";
import {
  approveToken,
  getProvider,
  getTokenAllowance,
  getWeb3,
  waitForConfirmation,
} from ".";
import MafiaFamilyBank from "../../abis/MafiaFamilyBank.json";
import config from "../../config/config";

export const getFamilyBankContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.familyBankAddress;
  const FamilyBankContract = new web3.eth.Contract(
    MafiaFamilyBank,
    contractAddress
  );
  return FamilyBankContract;
};

export const approveFamilyBankContract = async (
  account: string,
  approveAmount: number
) => {
  const tokenAddress = config.mafiaAddress;
  const contractAddress = config.familyBankAddress;

  const allowance = await getTokenAllowance(
    tokenAddress,
    account,
    contractAddress
  );

  if (Number(allowance) < approveAmount) {
    await approveToken(tokenAddress, approveAmount, contractAddress, account);
  }
};

export const createStaking = async (
  account: string,
  familyId: number,
  amount: number
) => {
  const web3 = getWeb3();
  const FamilyBankContract = getFamilyBankContract(true);

  const data = await FamilyBankContract.methods
    .deposit(familyId, web3.utils.toWei(amount.toString(), "ether"))
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const withdrawStaking = async (account: string, stakingId: number) => {
  const FamilyBankContract = getFamilyBankContract(true);

  const data = await FamilyBankContract.methods
    .withdraw(stakingId)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const getUserStakingAmount = async (account: string) => {
  const FamilyBankContract = getFamilyBankContract(true);

  const amount: number = await FamilyBankContract.methods
    .userStakingAmount(account)
    .call({ from: account });

  return parseInt(ethers.utils.formatUnits(amount, 18));
};

export const getLastWithdrawAt = async (account: string) => {
  const FamilyBankContract = getFamilyBankContract(true);

  const timestamp: number = await FamilyBankContract.methods
    .lastWithdrawAt(account)
    .call({ from: account });

  return Number(timestamp);
};
