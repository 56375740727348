import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FamilyDetailAllegiance from "../../components/FamilyDetailAllegiance";
import FamilyDetailBusiness from "../../components/FamilyDetailBusiness";
import FamilyDetailCapoRegimeList from "../../components/FamilyDetailCapoRegimeList";
import FamilyDetailHeader from "../../components/FamilyDetailHeader";
import FamilyDetailMemberList from "../../components/FamilyDetailMemberList";
import FamilyDetailPromotion from "../../components/FamilyDetailPromotion";
import FamilyEndStakingPopup from "../../components/FamilyEndStakingPopup";
import FamilyInfoPanels from "../../components/FamilyInfoPanels";
import FamilyInviteMemberPopup from "../../components/FamilyInviteMemberPopup";
import FamilyManageAllegiancePopup from "../../components/FamilyManageAllegiancePopup";
import FamilyManageFamilyPopup from "../../components/FamilyManageFamilyPopup";
import FamilyStakingPopup from "../../components/FamilyStakingPopup";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AllegiancePopupContent } from "../../constants/enum/enum";
import { getFamilyInfo } from "../../helper/contractFunctions/MafiaFamily";
import useVaultBalance from "../../hook/useVaultBalance";
import {
  getAllegianceDetailById,
  getAllegianceList,
  getFamilyBankInfo,
  getFamilyBusinesses,
  getFamilyDetailByName,
  getFamilyPromotion,
  setAllegianceDetail,
  setFamilyLeaveFee,
} from "../../reducers/family.slice";

import {
  isAllegianceCreator,
  isInAllegiance,
} from "../../helper/contractFunctions/FamilyAllegiance";

import useStyles from "./index.styles";
import FamilyDetailSlots from "../../components/FamilyDetailSlots";

const Family = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { familyName } = useParams();
  const vaultBalance = useVaultBalance();
  const { familyDetail } = useAppSelector((state) => state.family);

  const [openManageModal, setOpenManageModal] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openStakingModal, setOpenStakingModal] = useState(false);
  const [openAllegianceModal, setOpenAllegianceModal] = useState(false);
  const [openEndStakingModal, setOpenEndStakingModal] = useState(false);

  const [currentContent, setCurrentContent] = useState<AllegiancePopupContent>(
    AllegiancePopupContent.Join
  );

  useEffect(() => {
    dispatch(getFamilyDetailByName(familyName || ""));
    dispatch(getAllegianceList());
  }, [dispatch, familyName]);

  useEffect(() => {
    const fetchData = async () => {
      if (!familyDetail.id) return;
      try {
        dispatch(getFamilyPromotion(familyDetail.id));
        dispatch(getFamilyBankInfo(familyDetail.id));
        dispatch(getFamilyBusinesses(familyDetail.id));

        const familyInfo = await getFamilyInfo(familyDetail.id || 0);

        dispatch(
          setFamilyLeaveFee(Number(familyInfo.leaveFee) / Number(10 ** 18))
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [familyDetail.id, dispatch]);

  useEffect(() => {
    const fetchAllegianceInfo = async () => {
      if (!familyDetail.allegiance || !familyDetail.id) {
        setCurrentContent(AllegiancePopupContent.Join);
        return;
      }

      const allegianceId = familyDetail.allegiance.allegianceId;
      const isInAllegianceFlag = await isInAllegiance(familyDetail.id);
      const isAllegianceCreatorFlag = await isAllegianceCreator(
        allegianceId,
        familyDetail.id
      );

      if (isInAllegianceFlag) {
        dispatch(getAllegianceDetailById({ allegianceId }))
          .unwrap()
          .then((allegianceDetail: any) => {
            dispatch(setAllegianceDetail(allegianceDetail));
          })
          .catch((error) => {});

        if (isAllegianceCreatorFlag) {
          setCurrentContent(AllegiancePopupContent.Manage);
        } else {
          setCurrentContent(AllegiancePopupContent.Manage);
        }
      } else {
        setCurrentContent(AllegiancePopupContent.Join);
      }
    };

    fetchAllegianceInfo();
  }, [dispatch, familyDetail.allegiance, familyDetail.id]);

  return (
    <Box className={classes.body}>
      <FamilyDetailHeader
        vaultBalance={vaultBalance}
        setOpenManageModal={setOpenManageModal}
        setOpenInviteModal={setOpenInviteModal}
        setOpenStakingModal={setOpenStakingModal}
        setOpenAllegianceModal={setOpenAllegianceModal}
        setOpenEndStakingModal={setOpenEndStakingModal}
      />

      <FamilyInfoPanels vaultBalance={vaultBalance} />

      <FamilyDetailCapoRegimeList />

      <Box className={classes.container}>
        <FamilyDetailBusiness />
      </Box>

      <Box className={classes.container}>
        <FamilyDetailSlots />
      </Box>

      <Box className={classes.container}>
        <FamilyDetailPromotion />
        <FamilyDetailAllegiance />
      </Box>

      <FamilyDetailMemberList vaultBalance={vaultBalance} />

      <FamilyManageFamilyPopup
        vaultBalance={vaultBalance}
        openManageModal={openManageModal}
        setOpenManageModal={setOpenManageModal}
      />

      <FamilyInviteMemberPopup
        openInviteModal={openInviteModal}
        setOpenInviteModal={setOpenInviteModal}
      />

      <FamilyStakingPopup
        openStakingModal={openStakingModal}
        setOpenStakingModal={setOpenStakingModal}
      />

      <FamilyManageAllegiancePopup
        currentContent={currentContent}
        setCurrentContent={setCurrentContent}
        openManageModal={openAllegianceModal}
        setOpenManageModal={setOpenAllegianceModal}
      />

      <FamilyEndStakingPopup
        openModal={openEndStakingModal}
        setOpenModal={setOpenEndStakingModal}
      />
    </Box>
  );
};

export default Family;
