const configBsc = {
  mafiaAddress: "0x3cb3F4f43D4Be61AA92BB4EEFfe7142A13bf4111",
  pairAddress: "0xdE6e6378623C4F2c1102F2CcD35507d5bAf7924d",
  vaultAddress: "0xB88Aa2B2345eb37ab21Ed9359AF1c937ca6D07aF",
  vault1Address: "0xCABDd520EeDC6736F4C057Cba275673d74766A6b",
  profileTicketAddress: "0xa08D627E071cB4b53C6D0611d77dbCB659902AA4",

  mafiaFamilyAddress: "0x1bC581fe134BdC7432eF8ba75BCeEd242F90BcD2",
  familyBankAddress: "0xA7AB556Aac595A8425dDF584f3CA11bbD1772B8b",
  ogCrateAddress: "0x16B11C057cA6d354E81D58B375CB118f7930807c",
  crateMinterAddress: "0x1F4Eb51E87C4e2368316dba8e478Cd561FEb8B77",
  inventoryAddress: "0x2CB8352Be090846d4878Faa92825188D7bf50654",
  marketAddress: "0x1fb8C9F810afd99A6FAE3E81aBe0806f8796ba73",
  ogNFTAddress: "0xaf46bd44259b89f01B861C056C813228ADdfaD22",
  ogPremiumAddress: "0xA7AcE7F549BDE0b915EB06A6dAb3C9292cCa8B45",
  familyAllegianceAddress: "0x6fC9ba91179207764eDb537dD313C7cd3DAAaDEB",

  gamebankAddress: "0x376554F7BbcdeB348fa4b8371135B87eC6b29c38",
  exchangeAddress: "0x605694A29c5258D6c7Aed642D01111c4b7036966",
  depositAddress: "0xB081EC0763360a9Ad4D09AF2C9ec7DC1ED5190Ae",
  vaultSplitterAddress: "0x46f3F348a21BFEE36E1EacA57E91C08c733b73eD",

  mapAddress: "0x43b0D804dB511F6f68952f35b0D04BeD01581F06", //FIXME: Update contract address

  chainSymbolFull: "BNB",
  chainSymbol: "BNB",
  chainName: "Binance Smart Chain",
  chainId: 56,
  chainIdHex: 0x38,
  blockTime: 3000,

  providerList: [
    "https://alpha-weathered-card.bsc.quiknode.pro/5ee1c5dc4700fd50e42762ca281bf35b7dc36b88",
  ],

  explorerUrl: "https://bscscan.com/",
  nativePriceUrl:
    "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd",
  mafiaPriceUrl: "https://api.dexscreener.com/latest/dex/pairs/bsc/",

  domain: "bnbmafia.io",
  title: "BnbMafia.io",
  gitbookUrl: "http://bnbmafia.gitbook.io/",

  serverEndpoint: "https://backend.bnbmafia.io",
  mockServerEndpoint: "http://localhost:5000",
  testServerEndpoint: "https://9cw04922-3000.usw3.devtunnels.ms",

  newFirebaseConfig: {
    apiKey: "AIzaSyArUN3H-Toru5EBdJGano9yrg5YvjOcY3Q",
    authDomain: "bnbmafia-4d1b3.firebaseapp.com",
    projectId: "bnbmafia-4d1b3",
    storageBucket: "bnbmafia-4d1b3.appspot.com",
    messagingSenderId: "4235046852",
    appId: "1:4235046852:web:bbbd2bb1b51fc82e4dbe91",
    measurementId: "G-34J5STVC8V",
  },

  storageBucket: "gs://bnbmafia-4d1b3.appspot.com",

  symbol: "$MAFIA",
};

export default configBsc;
