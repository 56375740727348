import { Box, Tab, Tabs } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import useStyles from "./index.styles";

import FamilyList from "../../components/FamilyList";
import LandingPlayerList from "../../components/LandingPlayerList";

import { SERVER_URL } from "../../config/config";
import useVaultBalance from "../../hook/useVaultBalance";
import { GeneralInfo } from "../../types/GeneralInfo";

const FamilyLeaderBoard: React.FC = () => {
  const { classes } = useStyles();
  const vault = useVaultBalance();

  const [playerCount, setPlayerCount] = useState<number>(0);
  const [tabIndex, setTabIndex] = useState<number>(0); // State for tab index

  useEffect(() => {
    const fetchGeneralInfo = async () => {
      try {
        const response = await axios.get<GeneralInfo>(
          `${SERVER_URL}/family/generalInfo`
        );
        const generalInfo: GeneralInfo = response.data;

        setPlayerCount(generalInfo.userCount || 0);
      } catch (error) {
        console.log("Failed to fetch general information:", error);
        setPlayerCount(0);
      }
    };

    fetchGeneralInfo();
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Box className={classes.body}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="inherit" // Use inherit to allow custom styling
        sx={{
          paddingTop: "64px",
          maxWidth: 1440,
          margin: "auto",
        }}
      >
        <Tab
          label="Family List"
          sx={{
            color: "white", // Change text color to white
            "&.Mui-selected": {
              color: "white", // Change selected tab text color to white
            },
            "&.Mui-focusVisible": {
              backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: Add focus effect
            },
          }}
        />
        <Tab
          label="Player List"
          sx={{
            color: "white", // Change text color to white
            "&.Mui-selected": {
              color: "white", // Change selected tab text color to white
            },
            "&.Mui-focusVisible": {
              backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: Add focus effect
            },
          }}
        />
      </Tabs>

      {tabIndex === 0 && <FamilyList />}
      {tabIndex === 1 && (
        <LandingPlayerList playerCount={playerCount} vault={vault} />
      )}
    </Box>
  );
};

export default FamilyLeaderBoard;
