import { getProvider, getWeb3, waitForConfirmation } from ".";
import config from "../../config/config";
import MafiaExchangeAbi from "../../abis/MafiaExchange.json";
import MafiaDepositAbi from "../../abis/MafiaDeposit.json";
import { OTCRequestItem } from "../../types/Contract/Exchange/OTCRequestItem";
import { BigNumber, ethers } from "ethers";
import { approveGameCash, getMafiaGameBankContract } from "./MafiaGameBank";
import { SwapToken } from "../../types/Contract/SwapToken";

export const getMafiaExchangeContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.exchangeAddress;
  const MafiaExchangeContract = new web3.eth.Contract(
    MafiaExchangeAbi,
    contractAddress
  );
  return MafiaExchangeContract;
};

export const getMafiaDepositContract = (provider = false) => {
  const web3 = provider ? getProvider() : getWeb3();
  const contractAddress = config.depositAddress;
  const MafiaDepositContract = new web3.eth.Contract(
    MafiaDepositAbi,
    contractAddress
  );
  return MafiaDepositContract;
};

export const getExchangeTotalFee = async () => {
  const MafiaGameBankContract = getMafiaExchangeContract();
  const balance: number = await MafiaGameBankContract.methods
    .totalFees()
    .call();

  return ethers.utils.formatUnits(balance, 18);
};

export const convertItems = async (itemIds: number[], account: string) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);

  const data = await MafiaExchangeContract.methods
    .convertItem(itemIds)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);

  return data;
};

export const createOTCTrade = async (
  itemIds: number[],
  requestItems: OTCRequestItem[],
  account: string
) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);
  const data = await MafiaExchangeContract.methods
    .createOTCOffer(itemIds, requestItems)
    .send({ from: account });
  await waitForConfirmation(data.transactionHash);

  return data;
};

export const acceptOTCTrade = async (
  offerId: number,
  itemIds: number[],
  account: string
) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);
  const data = await MafiaExchangeContract.methods
    .acceptOTCOffer(offerId, itemIds)
    .send({ from: account });
  await waitForConfirmation(data.transactionHash);

  return data;
};

export const cancelOTCTrade = async (offerId: number, account: string) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);
  const data = await MafiaExchangeContract.methods
    .cancelOTCOffer(offerId)
    .send({ from: account });
  await waitForConfirmation(data.transactionHash);

  return data;
};

export const cancelExpiredOTCOffers = async (
  offerIds: number[],
  account: string
) => {
  const MafiaExchangeContract = getMafiaExchangeContract(true);
  const data = await MafiaExchangeContract.methods
    .cancelExpiredOTCOffers(offerIds)
    .send({ from: account });
  await waitForConfirmation(data.transactionHash);

  return data;
};

export const approveInGameCashToDepositContract = async (
  account: string,
  amount: number
) => {
  const GameBankContract = getMafiaGameBankContract(true);

  const allowance = await GameBankContract.methods
    .allowances(account, config.depositAddress)
    .call();

  if (
    Number(ethers.utils.formatEther(ethers.BigNumber.from(allowance))) < amount
  ) {
    await approveGameCash(
      config.gamebankAddress,
      amount,
      config.depositAddress,
      account
    );
  }
};

export const addNewLiqudity = async (
  account: string,
  cash: number,
  cashPerMafia: number
) => {
  const depositContract = getMafiaDepositContract(true);
  const cashWei = ethers.utils.parseEther(cash.toString()).toString();
  const cashPerMafiaWei = ethers.utils
    .parseEther(cashPerMafia.toString())
    .toString();

  const data = await depositContract.methods
    .addLiquidity(cashWei, cashPerMafiaWei)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};

export const removeLiquidity = async (id: number, account: string) => {
  const depositeContract = getMafiaDepositContract(true);
  const data = await depositeContract.methods
    .removeLiquidity(id)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};

export const withdrawMAFIA = async (id: number, account: string) => {
  const depositeContract = getMafiaDepositContract(true);
  const data = await depositeContract.methods
    .withdrawMafia(id)
    .send({ from: account });

  await waitForConfirmation(data.transactionHash);
};

export const swapCash = async (
  tokenId: number,
  account: string,
  amount: number,
  tokenInfo: SwapToken
) => {
  const depositContract = getMafiaDepositContract(true);

  let data;
  if (tokenId === 0) {
    const nativeAmount = ethers.utils.parseEther(amount.toString()).toString();

    data = await depositContract.methods
      .swapCash(tokenId, nativeAmount)
      .send({ value: nativeAmount, from: account });
  } else {
    const amountWei = ethers.utils
      .parseUnits(amount.toString(), tokenInfo.decimal.toString())
      .toString();
    data = await depositContract.methods
      .swapCash(tokenId, amountWei)
      .send({ from: account });
  }

  await waitForConfirmation(data.transactionHash);
};

export const getSwapTokens = async () => {
  const depositContract = getMafiaDepositContract();

  let swapTokenInfo: [SwapToken[], number[]];
  swapTokenInfo = await depositContract.methods.getSwapTokens().call();

  return swapTokenInfo;
};

export const getCashWithEstimateSwap = async (amount: number) => {
  const depositContract = getMafiaDepositContract();

  const cash = (await depositContract.methods
    .estimateSwap(ethers.utils.parseEther(amount.toString()).toString())
    .call()) as BigNumber;
  return Number(ethers.utils.formatEther(cash));
};

export const getAvailablePrices = async () => {
  const depositContract = getMafiaDepositContract();

  const availablePrices = await depositContract.methods
    .getAvailablePrices()
    .call();

  return (availablePrices as BigNumber[]).map((item) =>
    Number(ethers.utils.formatEther(item))
  );
};
