import { Box } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { useCallback, useEffect } from "react";
import {
  createChart,
  LastPriceAnimationMode,
  LineData,
  SeriesMarker,
  Time,
} from "lightweight-charts";
import useMafiaPrice from "../../hook/useMafiaPrice";
import useStyles from "./index.styles";
import Logo from "../Logo";
import moneyIcon from "../../assets/imgs/exchange/money.png";
import { toUSDFormat } from "../../utils/utils";
import clsx from "clsx";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const DepositChart = ({ visiblePosiInfo }: { visiblePosiInfo: boolean }) => {
  const { chartHistory, availablePrices, cashTotalSupply } = useAppSelector(
    (state) => state.exchange
  );
  const { classes } = useStyles();

  const mafiaPrice = useMafiaPrice();

  const currentCashPrice = useCallback(() => {
    if (!mafiaPrice || !availablePrices.currentRatio) return 0;
    return mafiaPrice / availablePrices.currentRatio;
  }, [mafiaPrice, availablePrices.currentRatio]);

  const getPriceChangePercent = useCallback(() => {
    const nowTime = new Date().getTime() / 1000;
    const fromTime = nowTime - 24 * 3600;
    const cashPrice = currentCashPrice();
    if (!cashPrice || !chartHistory.length) return 0;

    let percent = 0;
    const item = chartHistory.find((item) => item.timestamp > fromTime);
    if (item) {
      const priceChange = cashPrice - item.priceUSD;
      percent = (priceChange / item.priceUSD) * 100;
    }
    return percent;
  }, [chartHistory, currentCashPrice]);

  useEffect(() => {
    let chartContainer: HTMLElement | null = null;
    let newChartElement: HTMLDivElement | null = null;

    if (
      chartHistory &&
      chartHistory.length > 0 &&
      availablePrices.currentRatio > 0 &&
      mafiaPrice > 0
    ) {
      const data = [...chartHistory];

      if (data.length > 0) {
        newChartElement = document.createElement("div");
        newChartElement.style.cssText = `width: 100%; height: 100%; min-height: ${
          visiblePosiInfo ? 450 : 350
        }px; position:relative`;

        chartContainer = document.getElementById("chart-container");

        const tooltip = document.createElement("div");

        Object.assign(tooltip.style, {
          position: "absolute",
          display: "none",
          zIndex: "100",
          color: "white",
          fontSize: "15px",
          backgroundColor: "#4f5354",
          borderRadius: "8px",
          justifyContent: "center",
          alignItems: "center",
          padding: "12px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
        });

        tooltip.style.setProperty(
          "::after",
          `
            content: '';
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 10px;
            border-style: solid;
            border-color: #4f5354 transparent transparent transparent;
            width: 0;
            height: 0;
          `
        );

        tooltip.textContent = "";
        newChartElement.appendChild(tooltip);

        if (!chartContainer) return;

        chartContainer.appendChild(newChartElement);

        const chart = createChart(newChartElement, {
          layout: {
            background: { color: "#171a1b" },
            textColor: "#DDD",
          },
          grid: {
            vertLines: { color: "#44444475" },
            horzLines: { color: "#44444475" },
          },
          rightPriceScale: {
            scaleMargins: {
              top: 0.15,
              bottom: 0.15,
            },
            borderVisible: false,
          },
          timeScale: {
            secondsVisible: true,
            tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
              const date = new Date(parseInt(time) * 1000);
              return `${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}.${date
                .getDate()
                .toString()
                .padStart(2, "0")}`;
            },
          },
        });

        const series = chart.addAreaSeries({
          lineColor: "#e0a119",
          lastPriceAnimation: LastPriceAnimationMode.Continuous,
          priceLineVisible: false,
          lineWidth: 4,
          lineType: 2,
          lastValueVisible: false,
          priceFormat: {
            type: "custom",
            minMove: 1e-9,
            formatter: (price: number) => {
              return `$${price.toFixed(6)}`;
            },
          },
        });

        const seriesData: LineData<Time>[] = [];
        data.forEach((item, index) => {
          seriesData.push({
            time: item.timestamp as Time,
            value: Number(item.priceUSD.toFixed(9)),
          });
        });

        series.setData(seriesData);

        const step =
          Math.floor(data.length / 30) === 0 ? 1 : Math.floor(data.length / 30);
        const markers: SeriesMarker<Time>[] = [];
        seriesData.forEach((point, index) => {
          if (index === seriesData.length - 1 || index % step === 0) {
            markers.push({
              time: point.time,
              position: "inBar",
              color: "#e0a119",
              shape: "circle",
              size: 0.5,
              text:
                index === seriesData.length - 1 ? point.value.toString() : "",
            });
          }
        });
        series.setMarkers(markers);

        const startTime = Number(data[0].timestamp);
        const endTime = Number(data[data.length - 1].timestamp);
        const timeRange = endTime - startTime;
        const padding = timeRange * 0.18;

        const subSeries = chart.addLineSeries({
          color: "transparent",
        });
        let subSeriesData: LineData<Time>[] = [];
        data.forEach((item, index) => {
          subSeriesData.push({
            time: (item.timestamp + padding || 0) as Time,
            value: item.priceUSD,
          });
        });
        subSeries.setData(subSeriesData);

        chart.timeScale().setVisibleRange({
          from: (startTime - padding) as Time,
          to: (endTime + padding) as Time,
        });

        chart.subscribeCrosshairMove((param) => {
          if (
            param.point === undefined ||
            !param.time ||
            param.point.x < 0 ||
            param.point.y < 0
          ) {
            tooltip.style.display = "none";
          } else {
            const timestamp = param.time;
            const detail = data.find(
              (item) => item.timestamp === Number(timestamp)
            );
            if (detail) {
              tooltip.innerHTML = `1 / ${detail.avgRatio.toFixed(
                2
              )}<br>$${detail.priceUSD.toFixed(9)}`;

              tooltip.style.display = "flex";
              tooltip.style.left = param.point.x - 50 + "px";
              tooltip.style.top = param.point.y - 60 + "px";
            }
          }
        });
      }
    }

    return () => {
      if (chartContainer && newChartElement) {
        chartContainer.removeChild(newChartElement);
      }
    };
  }, [chartHistory, mafiaPrice, availablePrices.currentRatio, visiblePosiInfo]);
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        paddingTop: "50px",
      }}
    >
      <Box id="chart-container"></Box>
      <Box className={classes.dateLabel}>DATE</Box>

      <Box className={classes.depositTitle}>
        <Box className={clsx(classes.chartTitle, classes.chartLogo)}>
          <Box component={"img"} src={moneyIcon} width={24} /> CASH /{" "}
          <Logo sx={{ width: 24 }}></Logo>MAFIA
        </Box>
        <Box className={clsx(classes.chartTitle, classes.priceLabelOnChart)}>
          Price: ${toUSDFormat(currentCashPrice(), 7)}
          <>
            {getPriceChangePercent() >= 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#32db29",
                }}
              >
                <ArrowDropUpIcon /> {toUSDFormat(getPriceChangePercent(), 2)}%
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                }}
              >
                <ArrowDropDownIcon /> {toUSDFormat(getPriceChangePercent(), 2)}%
              </Box>
            )}
          </>
        </Box>
        <Box className={clsx(classes.chartTitle, classes.priceLabelOnChart)}>
          <Box component={"img"} src={moneyIcon} width={24} /> Marketcap: $
          {toUSDFormat(cashTotalSupply * currentCashPrice(), 0)}
        </Box>
      </Box>
    </Box>
  );
};

export default DepositChart;
