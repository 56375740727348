import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import useStyles from "./index.styles";
import { useEffect, useMemo, useState } from "react";
import useWallet from "../../hook/useWallet";
import { toastError, toastInfo, toUSDFormat } from "../../utils/utils";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Errors } from "../../constants/errors";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import moneyIcon from "../../assets/imgs/exchange/money.png";
import {
  approveTokenToDepositTicketAction,
  estimateSwap,
  getSwapTokenBalance,
  getSwapTokenInfo,
  swapActivity,
} from "../../reducers/exchange.slice";
import TokenImage from "../TokenImage";
import clsx from "clsx";
import { Messages } from "../../constants/messages";
import useGameBankBalance from "../../hook/useGameBankBalance";
import millify from "millify";
import { tokenToString } from "typescript";
import { SwapToken } from "../../types/Contract/SwapToken";

const SwapPanel = ({
  setActivePanel,
}: {
  setActivePanel: (panel: string) => void;
}) => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const {
    swapTokenInfo,
    swapTokenBalances,
    estimatedCash,
    loadingEstimatedCash,
  } = useAppSelector((state) => state.exchange);
  const { signMsg, signature } = useAppSelector((state) => state.auth);
  const { myProfile } = useAppSelector((state) => state.profile);
  const { account, connectWallet } = useWallet();
  const { updateBalance } = useGameBankBalance(account, signMsg, signature);

  const [sellTokenAmount, setSellTokenAmount] = useState(0);
  const [sellUsdAmount, setSellUsdAmount] = useState("");
  const [selectedTokenId, setSelectedTokenId] = useState(0);
  const [activeMaxBtn, setActiveMaxBtn] = useState(false);
  const [oneTimeTrying, setOneTimeTrying] = useState(false);

  const swapTokenList = swapTokenInfo[0];
  const swapTokenPrice = swapTokenInfo[1];

  const selectedToken = useMemo(() => {
    if (
      !swapTokenList ||
      !swapTokenPrice ||
      !swapTokenList[selectedTokenId] ||
      !swapTokenPrice[selectedTokenId]
    )
      return;

    return {
      info: swapTokenList[selectedTokenId],
      price: swapTokenPrice[selectedTokenId],
    };
  }, [swapTokenList, swapTokenPrice, selectedTokenId]);

  const getSwapTokenUSDValue = (tokenId: number) => {
    return (
      swapTokenBalances[swapTokenList[tokenId].name] * swapTokenPrice[tokenId]
    );
  };
  
  const handleFromSwapTokenChange = (e: SelectChangeEvent<number>) => {
    setSelectedTokenId(Number(e.target.value));
  };

  const handleClickMax = () => {
    if (!selectedToken) return;

    setSellUsdAmount(
      (selectedToken.info
        ? Number(swapTokenBalances[selectedToken.info.name]) *
          swapTokenPrice[selectedTokenId]
        : 0
      ).toString()
    );
    setActiveMaxBtn(true);
  };

  const handleClickSwap = async () => {
    if (!account) {
      return connectWallet();
    }
    if (!selectedToken) return;

    if (Number(swapTokenBalances[selectedToken.info.name]) < sellTokenAmount) {
      return toastError(Errors.EXCHANGE.SWAP.NOT_ENOUGH_BALANCE);
    }
    if (!(sellTokenAmount > 0)) {
      return toastError(Errors.EXCHANGE.SWAP.INPUT_TOKEN_AMOUNT);
    }
    if (estimatedCash === 0) {
      return toastError(Errors.EXCHANGE.SWAP.INSUFFICIENT_LP);
    }

    if (selectedTokenId !== 0) {
      dispatchTxAction(
        dispatch,
        approveTokenToDepositTicketAction({
          tokenId: selectedTokenId,
          account,
          sellTokenAmount: sellTokenAmount,
          tokenInfo: selectedToken.info,
        }),
        async () => {
          try {
            await dispatch(
              swapActivity({
                tokenId: selectedTokenId,
                account,
                amount: sellTokenAmount,
                tokenInfo: selectedToken.info,
                estimatedCash,
              })
            ).unwrap();
            dispatch(getSwapTokenInfo());
            updateBalance(account, signMsg, signature);
          } catch (err) {
            console.error(err);
          }
        },
        3000
      );
    } else {
      try {
        await dispatch(
          swapActivity({
            tokenId: selectedTokenId,
            account,
            amount: sellTokenAmount,
            tokenInfo: selectedToken.info,
            estimatedCash,
          })
        ).unwrap();
        dispatch(getSwapTokenInfo());
        updateBalance(account, signMsg, signature);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    dispatch(getSwapTokenInfo());
  }, [dispatch]);

  useEffect(() => {
    if (swapTokenList && account) {
      dispatch(getSwapTokenBalance({ account, swapTokenList }));
    }
  }, [dispatch, account, swapTokenList, estimatedCash]);

  useEffect(() => {
    if (
      Object.keys(swapTokenBalances).length === 0 ||
      swapTokenInfo[1].length === 0
    )
      return;

    if (oneTimeTrying) return;

    const maxIndex = swapTokenList
      .filter((token) => token.isEnabled)
      .reduce((maxValueTokenId, token) => {
        return getSwapTokenUSDValue(token.tokenId) >
          getSwapTokenUSDValue(maxValueTokenId)
          ? token.tokenId
          : maxValueTokenId;
      }, 0);

    setSelectedTokenId(maxIndex);
    setOneTimeTrying(true);
  }, [swapTokenBalances, swapTokenInfo, oneTimeTrying]);

  useEffect(() => {
    if (!selectedToken) return;
    setActiveMaxBtn(false);
    const mafiaAmount =
      (sellTokenAmount *
        selectedToken.price *
        (selectedToken.info.name === "MAFIA" ? 1 : 0.953)) /
      swapTokenPrice[1];
    dispatch(estimateSwap({ amount: mafiaAmount }));
  }, [dispatch, sellTokenAmount, selectedToken, swapTokenPrice]);

  useEffect(() => {
    const sellUsd = Number(sellUsdAmount);
    if (!sellUsd) {
      setSellTokenAmount(0);
      return;
    }
    const balance = sellUsd / swapTokenPrice[selectedTokenId];
    setSellTokenAmount(balance);
  }, [dispatch, sellUsdAmount, swapTokenPrice, selectedTokenId]);

  return (
    <Box className={classes.swapPanel}>
      <Box className={classes.buttonsGroup}>
        <Button
          variant="contained"
          className={classes.swapLinkButton}
          sx={{
            backgroundColor: "#1e1f21",
          }}
        >
          Swap
        </Button>
        <Button
          variant="contained"
          className={classes.swapLinkButton}
          sx={{ backgroundColor: "transparent" }}
          onClick={() => setActivePanel("withdraw")}
        >
          Liquidity
        </Button>
      </Box>
      <Box className={classes.sellPanel} marginTop={4} zIndex={2}>
        <Box className={classes.sellText}>Sell</Box>
        <Box className={classes.swapItem}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            $
            <TextField
              value={sellUsdAmount}
              className={classes.sellInput}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSellUsdAmount(e.target.value);
              }}
              placeholder="0.0"
            />
          </Box>
          <FormControl className={classes.swapTokenSelect}>
            <Select
              onChange={handleFromSwapTokenChange}
              className={classes.selectItem}
              value={selectedTokenId}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: "#1e1f21",
                  },
                },
              }}
              SelectDisplayProps={{}}
              IconComponent={(props) => (
                <KeyboardArrowDownIcon
                  {...props}
                  sx={{
                    color: "white !important",
                    marginRight: "24px",
                    fontSize: "32px",
                  }}
                />
              )}
            >
              {swapTokenList
                .slice()
                .filter((token) => token.isEnabled)
                .sort(
                  (a, b) =>
                    getSwapTokenUSDValue(b.tokenId) -
                    getSwapTokenUSDValue(a.tokenId)
                )
                .map((item, index) => (
                  <MenuItem
                    value={item.tokenId}
                    key={index}
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <TokenImage token={item.name} sx={{ width: "30px" }} />
                      {item.name}
                    </Box>
                    <Box>
                      {millify(Number(swapTokenBalances[item.name]) || 0, {
                        precision: 1,
                      })}{" "}
                      ($
                      {toUSDFormat(
                        Number(swapTokenBalances[item.name] || 0) *
                          swapTokenPrice[item.tokenId]
                      )}
                      )
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Box className={classes.swapIconBox}>
            <ArrowDownwardIcon />
          </Box>
        </Box>
        <Box className={classes.swapItem}>
          <Box>
            {toUSDFormat(sellTokenAmount, 3)} {selectedToken?.info?.name}
          </Box>
          <Box sx={{ display: "flex", gap: "8px" }}>
            {selectedToken && account
              ? millify(
                  selectedToken.info
                    ? Number(swapTokenBalances[selectedToken.info.name])
                    : 0,
                  { precision: 2 }
                )
              : 0}{" "}
            {(selectedToken && selectedToken.info.name) || ""}
            ($
            {toUSDFormat(
              (selectedToken?.info
                ? Number(swapTokenBalances[selectedToken.info.name])
                : 0) * swapTokenPrice[selectedTokenId]
            )}
            ){" "}
            <Button
              className={clsx(
                classes.maxButton,
                activeMaxBtn ? classes.activeButton : null
              )}
              variant="contained"
              onClick={handleClickMax}
            >
              MAX
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.sellPanel} zIndex={1}>
        <Box className={classes.sellText}>Buy</Box>
        <Box className={classes.swapItem}>
          <Box>
            <TextField
              className={classes.sellInput}
              value={
                loadingEstimatedCash
                  ? ""
                  : estimatedCash === 0 &&
                    Math.floor(Number(sellUsdAmount)) !== 0
                  ? "Insufficient Liquidity"
                  : toUSDFormat(estimatedCash, 0)
              }
              InputProps={{
                endAdornment: loadingEstimatedCash ? (
                  <CircularProgress size={20} />
                ) : null,
              }}
              placeholder="0.0"
              aria-readonly
            />
          </Box>
          <FormControl className={classes.swapTokenSelect}>
            <Select
              className={classes.selectItem}
              value={0}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: "#1e1f21",
                  },
                },
              }}
              SelectDisplayProps={{}}
              IconComponent={(props) => (
                <KeyboardArrowDownIcon
                  {...props}
                  sx={{
                    color: "white !important",
                    marginRight: "24px",
                    fontSize: "32px",
                  }}
                />
              )}
              readOnly
            >
              <MenuItem
                value={0}
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Box
                    component={"img"}
                    src={moneyIcon}
                    sx={{ width: "24px" }}
                  />
                  Cash
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.swapItem}>
          <Box>
            $
            {toUSDFormat(
              sellTokenAmount *
                swapTokenPrice[selectedTokenId] *
                (selectedToken && selectedToken.info.name === "MAFIA"
                  ? 1
                  : 0.953)
            )}
          </Box>
          <Box sx={{ opacity: 0.4 }}>
            Fee:{" "}
            {selectedToken && selectedToken.info.name === "MAFIA" ? "0" : "4.7"}
            %
          </Box>
        </Box>
      </Box>
      <Box className={classes.swapButtonBox}>
        <Button
          variant="contained"
          className={classes.swapButton}
          onClick={handleClickSwap}
          disabled={estimatedCash > 0 && myProfile.name ? false : true}
        >
          Swap
        </Button>
      </Box>
    </Box>
  );
};

export default SwapPanel;
