export enum InvitationStatus {
  OPEN = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  CANCELED = 3,
}

export enum ChatType {
  Global,
  Family,
}

export enum Gender {
  MALE = 0,
  FEMALE = 1,
}

export type InventoryType = "Items" | "NFTs" | "All";

export enum ItemCategory {
  CASH = 0,
  BULLET = 1,
  HEALTH = 2,
  SHOPITEM = 3,
  BUSINESS = 4,
  BODYGUARD = 5,
  CREDIT = 6,
  CAR = 7,
  KEY = 8,
  KEYITEMS = 9,
  MAFIA = 10,
  OGNFT = 11,
  NOTICREDIT = 12,

  LANDSLOT = 130,
}

export enum AnimationStatus {
  Stopped = 0,
  SpeedUp = 1,
  Playing = 2,
  SpeedDown = 3,
}

export enum FamilyRole {
  Don = 0,
  Consigliere = 1,
  Capodecina = 2,
  Capo1 = 3,
  Capo2 = 4,
  Capo3 = 5,
  Capo4 = 6,
  Capo5 = 7,
  Successor = 8,
  Member = 9,
}

export enum ChainType {
  BNB = "BNB",
  PLS = "PLS",
}

export enum MarketSSEEventHeader {
  NewListing = "NewListing",
  ItemPurchased = "ItemPurchased",
  NewBid = "NewBid",
  CancelListing = "CancelListing",
}

export enum AllegiancePopupContent {
  Join,
  Create,
  Manage,
}

export enum NotificationType {
  FamilyInvitation = 0,
  AllegianceInvitation = 1,
  AllegianceRequest = 2,
}
