import { useGSAP } from "@gsap/react";
import { Box } from "@mui/material";
import clsx from "clsx";
import gsap from "gsap";
import isMobile from "is-mobile";
import { useEffect, useMemo, useRef } from "react";

import { ItemCategoryInfoList } from "../../constants/const";
import { AnimationStatus, ItemCategory } from "../../constants/enum/enum";

import {
  SpeedDownDuration,
  SpeedUpDuration,
  SpinningCount,
  SpinningCountMobile,
  SpinningSubDuration,
} from "../../constants/animationDurations";

import { ItemInfo } from "../../types/ItemInfo";
import useStyles from "./index.styles";

gsap.registerPlugin(useGSAP);

const PrizeReel = ({
  animationStatus,
  width,
  itemInfo,
  businessAvailable,
}: {
  animationStatus: AnimationStatus;
  width: number;
  itemInfo: ItemInfo;
  businessAvailable: boolean;
}) => {
  // Key Removed
  // Business Items, Key Removed
  const listItemCount =
    ItemCategoryInfoList.length - (businessAvailable ? 2 : 3);

  const prizeListItemWidth = isMobile() ? 76 : 100;
  const prizeListItemGap = isMobile() ? 8 : 16;
  const prizeSubListWidth =
    prizeListItemWidth * (2 + listItemCount) +
    prizeListItemGap * (listItemCount + 1);
  const prizeSubListCount = isMobile() ? 4 : 6;

  const prizeSelectionRangeOffset = isMobile() ? 0 : 2;

  const { classes } = useStyles();
  const container = useRef<HTMLDivElement>();

  const tl = useRef<gsap.core.Timeline>();
  const prizeItemSelector = ".prizeItem";

  const startSpeedUpAnimation = () => {
    tl.current = gsap.timeline();
    if (!container.current) return;

    const speedUpMoveX = (container.current?.clientWidth || 0) - width;

    tl.current
      ?.to(
        container.current,
        {
          x: speedUpMoveX,
          duration: SpeedUpDuration,
          ease: "power4.in",
        },
        0
      )
      .pause();

    tl.current.play();
  };

  const startSpinningAnimation = () => {
    tl.current = gsap.timeline();

    if (!container.current) return;

    console.log("Starting spinning animation");

    const fromX = prizeSubListWidth - width;
    const toX = (container.current?.clientWidth || 0) - width;

    tl.current
      .set(container.current, { x: fromX })
      .to(
        container.current,
        { x: toX, duration: SpinningSubDuration, ease: "linear" },
        0
      )
      .repeat(isMobile() ? SpinningCountMobile - 1 : SpinningCount - 1)
      .pause();

    tl.current.play();
  };

  const startSpeedDownAnimation = () => {
    tl.current = gsap.timeline();

    if (!container.current) return;

    console.log("Starting speed down animation");

    const prizeItems: HTMLDivElement[] = gsap.utils.toArray(prizeItemSelector);

    // Prize type selection
    let itemIndex = itemInfo.categoryId;
    console.log(`Selecting ${ItemCategoryInfoList[itemIndex].name} item`);

    let newItemIdex = itemIndex;
    // Get new item index based on the business objects availability
    if (!businessAvailable) {
      switch (itemIndex) {
        case ItemCategory.BODYGUARD:
        case ItemCategory.CREDIT:
        case ItemCategory.CAR:
          newItemIdex -= 1;
          break;

        case ItemCategory.KEYITEMS:
        case ItemCategory.MAFIA:
        case ItemCategory.OGNFT:
        case ItemCategory.NOTICREDIT:
          newItemIdex -= 2;
          break;
      }
    } else {
      switch (itemIndex) {
        case ItemCategory.KEYITEMS:
        case ItemCategory.MAFIA:
        case ItemCategory.OGNFT:
        case ItemCategory.NOTICREDIT:
          newItemIdex -= 1;
          break;
      }
    }

    const randomItemIndex =
      newItemIdex +
      listItemCount +
      Math.floor(Math.random() * prizeSelectionRangeOffset) * listItemCount;

    const selectedItem = prizeItems[randomItemIndex];
    const randomOffset =
      Math.floor(Math.random() * prizeListItemWidth * 0.75) +
      prizeListItemGap * 2;

    const fromX = prizeSubListWidth - width;
    const toX =
      (container.current?.clientWidth || 0) - // Total width of reel
      randomItemIndex * prizeListItemWidth - // Sum of item width
      (randomItemIndex - 1) * prizeListItemGap - // Sum of item gap
      width / 2 - // To distance to reel stop
      randomOffset; // Random offset

    tl.current
      .set(container.current, { x: fromX })
      .set(selectedItem, { scale: 1, filter: "brightness(1)" })
      .to(container.current, {
        x: toX,
        duration: SpeedDownDuration,
        ease: "power3.out",
      })
      .fromTo(
        selectedItem,
        { scale: 1, filter: "brightness(1)" },
        { scale: 1.1, duration: 0.3, filter: "brightness(2)" }
      )
      .fromTo(
        selectedItem,
        { scale: 1.1, filter: "brightness(2)" },
        {
          scale: 1,
          duration: 0.3,
          filter: "brightness(1)",
        }
      )
      .pause();
    tl.current.play();
  };

  useGSAP(
    () => {
      gsap.from(prizeItemSelector, { opacity: 0, stagger: 0.01 });
    },
    { scope: container }
  );

  useEffect(() => {
    if (animationStatus === AnimationStatus.SpeedUp) {
      startSpeedUpAnimation();
    } else if (animationStatus === AnimationStatus.Playing) {
      startSpinningAnimation();
    } else if (animationStatus === AnimationStatus.SpeedDown) {
      startSpeedDownAnimation();
    }
  }, [animationStatus]);

  const filteredItemCategoryList = useMemo(() => {
    if (businessAvailable) {
      return ItemCategoryInfoList.filter((category, index) => {
        return (
          category.id !== ItemCategory.KEY &&
          category.id !== ItemCategory.LANDSLOT
        );
      });
    } else {
      return ItemCategoryInfoList.filter((category, index) => {
        return (
          category.id !== ItemCategory.KEY &&
          category.id !== ItemCategory.BUSINESS &&
          category.id !== ItemCategory.LANDSLOT
        );
      });
    }
  }, [businessAvailable]);

  return (
    <>
      <Box className={classes.reel}>
        <Box className={classes.prizeItemList} ref={container}>
          {[
            ...new Array(prizeSubListCount)
              .fill(filteredItemCategoryList)
              .flat(),
          ].map((prize, index) => {
            return (
              <Box className={clsx(classes.prizeItem, "prizeItem")} key={index}>
                <Box
                  component="img"
                  src={`/assets/imgs/inventory/${prize.icon}`}
                />
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box className={classes.reelStop}></Box>
    </>
  );
};

export default PrizeReel;
