import { Box } from "@mui/material";

import { useAppSelector } from "../../app/hooks";

import businessIcon from "../../assets/imgs/family/map.png";

import useStyles from "./index.styles";

const FamilyDetailSlots = () => {
  const { classes } = useStyles();

  const { familyDetail } = useAppSelector((state) => state.family);

  return (
    <Box className={classes.businessCard}>
      <Box className={classes.businessCardHeader}>
        <Box className={classes.cardTitle}>
          <Box component="img" src={businessIcon}></Box>
          <Box>Family owned tiles</Box>
        </Box>
        <Box className={classes.ownedTitle}>
          {familyDetail.businessObjects?.length} Owned
        </Box>
      </Box>
      <Box className={classes.businessEmpty}>
        <Box className={classes.detailsBody}>No tiles owned by this family</Box>
      </Box>
    </Box>
  );
};

export default FamilyDetailSlots;
