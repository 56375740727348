import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { SERVER_URL } from "../config/config";

import { signMessage } from "../helper/contractFunctions";
import {
  acceptAllegianceInvitation,
  acceptRequest,
  createAllegiance,
  exitAllegiance,
  inviteAllegianceFamily,
  kickAllegianceMember,
  rejectAllegianceInvitation,
  rejectRequest,
  requestToJoinAllegiance,
} from "../helper/contractFunctions/FamilyAllegiance";
import {
  acceptInvitation,
  approveFamilyContract,
  cancelInvitation,
  createFamily,
  inviteMember,
  kickMember,
  leaveFamily,
  rejectInvitation,
  setSuccessor,
  updateFamilyLeaveFee,
  updateLeader,
} from "../helper/contractFunctions/MafiaFamily";
import {
  approveFamilyBankContract,
  createStaking,
  withdrawStaking,
} from "../helper/contractFunctions/MafiaFamilyBank";

import { WalletErrorMsg } from "../constants/const";

import { parseFamilyAllegianceInfo } from "../helper/family";
import { FamilyAllegianceInfo } from "../types/FamilyAllegianceInfo";
import { BusinessInfo } from "../types/FamilyBusinessInfo";
import { FamilyDetail } from "../types/FamilyDetails";
import { FamilyInfo } from "../types/FamilyInfo";
import { FamilyInvitation } from "../types/FamilyInvitation";
import { AllegianceDetail } from "../types/AllegianceDetail";

export interface FamilyState {
  familyList: FamilyInfo[];
  isLoadingFamilyList: boolean;

  allegianceList: FamilyAllegianceInfo[];
  isLoadingAllegianceList: boolean;

  allegianceDetail: AllegianceDetail;

  invitationList: FamilyInvitation[];

  familyDetail: FamilyDetail;

  isLoadingFamilyDetail: boolean;
  isSigningData: boolean;

  isApprovingFamilyCreate: boolean;
  isPerformingFamilyCreate: boolean;
  isCreatingFamily: boolean;

  isInvitingFamilyMember: boolean;

  isApprovingBankStaking: boolean;
  isPerformingStaking: boolean;
  isCreatingStaking: boolean;
  isWithdrawingStaking: boolean;

  isUpdatingLeader: boolean;
  isAcceptingInvitation: boolean;
  isRejectingInvitation: boolean;
  isCancelingInvitation: boolean;

  isLeavingFamily: boolean;
  isKickingMember: boolean;

  isUpdatingFamilyPromotion: boolean;
  isUpdatingFamilyBranding: boolean;
  isUpdatingFamilyLeaveFee: boolean;
  isUpdatingFamilySharing: boolean;
  isUpdatingFamilyCapoRegime: boolean;

  isCreatingAllegiance: boolean;
  isInvitingAllegiance: boolean;
  isAcceptingAllegiance: boolean;
  isRejectingAllegiance: boolean;
  isKickingAllegiance: boolean;
  isExitingAllegiance: boolean;
  isRegisteringAllegiance: boolean;

  isRequestingToJoinAllegiance: boolean;
  isAcceptingAllegianceRequest: boolean;
  isRejectingAllegianceRequest: boolean;

  isUpdatingAllegianceSharing: boolean;
  isUpdatingAllegianceLogo: boolean;
}

const initialState: FamilyState = {
  familyList: [],
  isLoadingFamilyList: false,

  allegianceList: [],
  isLoadingAllegianceList: false,

  allegianceDetail: { familyData: [] },

  invitationList: [],

  familyDetail: {
    members: [],
    capoRegime: [],
    background: null,
    logo: null,
    leaveFee: 0,
    discord: "",
    telegram: "",
    twitter: "",
    topPercent: 100,

    promotion: null,
    bankInfo: null,
    businessObjects: null,
  },

  isLoadingFamilyDetail: false,
  isSigningData: false,

  isApprovingFamilyCreate: false,
  isPerformingFamilyCreate: false,
  isCreatingFamily: false,

  isInvitingFamilyMember: false,

  isApprovingBankStaking: false,
  isPerformingStaking: false,
  isCreatingStaking: false,
  isWithdrawingStaking: false,

  isUpdatingLeader: false,
  isAcceptingInvitation: false,
  isRejectingInvitation: false,
  isCancelingInvitation: false,

  isLeavingFamily: false,
  isKickingMember: false,

  isUpdatingFamilyPromotion: false,
  isUpdatingFamilyBranding: false,
  isUpdatingFamilyLeaveFee: false,
  isUpdatingFamilySharing: false,
  isUpdatingFamilyCapoRegime: false,

  isCreatingAllegiance: false,
  isInvitingAllegiance: false,
  isAcceptingAllegiance: false,
  isRejectingAllegiance: false,
  isKickingAllegiance: false,
  isExitingAllegiance: false,
  isRegisteringAllegiance: false,

  isRequestingToJoinAllegiance: false,
  isAcceptingAllegianceRequest: false,
  isRejectingAllegianceRequest: false,

  isUpdatingAllegianceSharing: false,
  isUpdatingAllegianceLogo: false,
};

//#region Update family info

export const updateFamilyPromotionAction = createAsyncThunk(
  "family/updateFamilyPromotion",
  async ({
    signMsg,
    signature,
    familyId,
  }: {
    signature: string;
    signMsg: any;
    familyId: number;
  }) => {
    const response = await axios.post(`${SERVER_URL}/family/promotion`, {
      signature,
      signMsg,
      familyId,
    });

    return response;
  }
);

export const updateFamilyBrandingAction = createAsyncThunk(
  "family/updateFamilyBranding",
  async ({
    signMsg,
    signature,
    familyId,
  }: {
    signature: string;
    signMsg: any;
    familyId: number;
  }) => {
    const response = await axios.post(`${SERVER_URL}/family/set`, {
      signature,
      signMsg,
      familyId,
    });

    return response;
  }
);

export const updateCapoRegimeAction = createAsyncThunk(
  "family/updateCapoRegime",
  async ({ signMsg, signature }: { signature: string; signMsg: any }) => {
    const response = await axios.post(`${SERVER_URL}/family/capo`, {
      signature,
      signMsg,
    });

    return response;
  }
);

export const updateFamilySharingAction = createAsyncThunk(
  "family/updateFamilySharing",
  async ({ signMsg, signature }: { signature: string; signMsg: any }) => {
    const response = await axios.post(`${SERVER_URL}/family/share`, {
      signature,
      signMsg,
    });

    return response;
  }
);

export const updateAllegianceSharingAction = createAsyncThunk(
  "family/updateAllegianceSharingAction",
  async ({ signMsg, signature }: { signature: string; signMsg: any }) => {
    const response = await axios.put(`${SERVER_URL}/family/allegianceShare`, {
      signature,
      signMsg,
    });

    return response;
  }
);

export const updateAllegianceLogo = createAsyncThunk(
  "family/updateAllegianceLogo",
  async ({ signMsg, signature }: { signature: string; signMsg: any }) => {
    const response = await axios.put(`${SERVER_URL}/allegiance`, {
      signature,
      signMsg,
    });

    return response;
  }
);
//#endregion

//#region Retrieving Family Info

export const getAllegianceDetailById = createAsyncThunk(
  "family/getAllegianceDetailById",
  async ({ allegianceId }: { allegianceId: number }) => {
    const response = await axios.get(
      `${SERVER_URL}/allegiance/${allegianceId}`
    );

    return response.data;
  }
);

export const getAllegianceList = createAsyncThunk(
  "family/getAllegianceList",
  async () => {
    const response = await axios.get(`${SERVER_URL}/allegiance`);

    return response.data;
  }
);

export const getFamilyList = createAsyncThunk(
  "family/getFamilyList",
  async () => {
    const response = await axios.get(`${SERVER_URL}/family`);

    return response.data;
  }
);

export const getFamilyDetailByName = createAsyncThunk(
  "family/getFamilyDetailByName",
  async (familyName: string) => {
    const response = await axios.get(`${SERVER_URL}/family/name/${familyName}`);

    return response.data;
  }
);

export const getFamilyDetailById = createAsyncThunk(
  "family/getFamilyDetailById",
  async (familyId: number) => {
    const response = await axios.get(`${SERVER_URL}/family/id/${familyId}`);

    return response.data;
  }
);

export const getFamilyInvitations = createAsyncThunk(
  "family/getFamilyInvitations",
  async (account: string) => {
    const response = await axios.get(
      `${SERVER_URL}/family/invitation/${account}`
    );

    return response.data;
  }
);

export const getFamilyPromotion = createAsyncThunk(
  "family/getFamilyPromotion",
  async (familyId: number) => {
    const response = await axios.get(
      `${SERVER_URL}/family/promotion/${familyId}`
    );

    return response.data;
  }
);

export const getFamilyBankInfo = createAsyncThunk(
  "family/getFamilyBankInfo",
  async (familyId: number) => {
    const response = await axios.get(`${SERVER_URL}/family/bank/${familyId}`);

    return response.data;
  }
);

export const getFamilyBusinesses = createAsyncThunk(
  "family/getFamilyBusinesses",
  async (familyId: number) => {
    const response = await axios.get(
      `${SERVER_URL}/family/business/${familyId}`
    );

    return response.data.businessObjects;
  }
);

//#endregion

//#region Family contract interaction
export const createFamilyAction = createAsyncThunk(
  "family/createFamily",
  async (account: string) => {
    const data = await createFamily(account);

    return data.transactionHash;
  }
);

export const approveFamilyAction = createAsyncThunk(
  "family/approveFamily",
  async ({
    account,
    approveAmount,
  }: {
    account: string;
    approveAmount: number;
  }) => {
    await approveFamilyContract(account, approveAmount);
  }
);

export const approveBankAction = createAsyncThunk(
  "family/approveBank",
  async ({
    account,
    approveAmount,
  }: {
    account: string;
    approveAmount: number;
  }) => {
    await approveFamilyBankContract(account, approveAmount);
  }
);

export const createBankStakingAction = createAsyncThunk(
  "family/createBankStakingAction",
  async ({
    account,
    familyId,
    amount,
  }: {
    account: string;
    familyId: number;
    amount: number;
  }) => {
    const data = await createStaking(account, familyId, amount);

    return data.transactionHash;
  }
);

export const withdrawBankStakingAction = createAsyncThunk(
  "family/withdrawBankStakingAction",
  async ({ account, stakingId }: { account: string; stakingId: number }) => {
    const data = await withdrawStaking(account, stakingId);

    return data.transactionHash;
  }
);

export const registerFamilyCreateAction = createAsyncThunk(
  "family/registerFamily",
  async ({ signMsg, signature }: { signMsg: any; signature: string }) => {
    const response = await axios.post(`${SERVER_URL}/family`, {
      signature,
      signMsg,
    });

    return response.data.name;
  }
);

export const inviteFamilyMemberAction = createAsyncThunk(
  "family/inviteFamilyMember",
  async ({
    familyId,
    account,
    invitee,
  }: {
    familyId: number;
    account: string;
    invitee: string;
  }) => {
    const data = await inviteMember(account, familyId, invitee);

    return data.transactionHash;
  }
);

export const acceptFamilyInvitationAction = createAsyncThunk(
  "family/acceptFamilyInvitation",
  async ({
    account,
    invitationId,
  }: {
    account: string;
    invitationId: number;
  }) => {
    const data = await acceptInvitation(account, invitationId);

    return data.transactionHash;
  }
);

export const rejectFamilyInvitationAction = createAsyncThunk(
  "family/rejectFamilyInvitation",
  async ({
    account,
    invitationId,
  }: {
    account: string;
    invitationId: number;
  }) => {
    const data = await rejectInvitation(account, invitationId);

    return data.transactionHash;
  }
);

export const cancelFamilyInvitationAction = createAsyncThunk(
  "family/cancelFamilyInvitation",
  async ({
    account,
    invitationId,
  }: {
    account: string;
    invitationId: number;
  }) => {
    const data = await cancelInvitation(account, invitationId);

    return data.transactionHash;
  }
);

export const updateFamilyLeaderAction = createAsyncThunk(
  "family/updateFamilyLeader",
  async ({
    account,
    familyId,
    memberAddress,
    leaderIndex,
  }: {
    account: string;
    familyId: number;
    memberAddress: string;
    leaderIndex: number;
  }) => {
    const data = await updateLeader(
      account,
      familyId,
      memberAddress,
      leaderIndex
    );

    return data.transactionHash;
  }
);

export const updateFamilySuccessorAction = createAsyncThunk(
  "family/updateFamilySuccessor",
  async ({
    account,
    familyId,
    successor,
  }: {
    account: string;
    familyId: number;
    successor: string;
  }) => {
    const data = await setSuccessor(account, familyId, successor);

    return data.transactionHash;
  }
);

export const leaveFamilyAction = createAsyncThunk(
  "family/leaveFamily",
  async ({ account, familyId }: { account: string; familyId: number }) => {
    const data = await leaveFamily(account, familyId);

    return data.transactionHash;
  }
);

export const kickMemberAction = createAsyncThunk(
  "family/kickMemberAction",
  async ({
    account,
    familyId,
    memberAddress,
  }: {
    account: string;
    familyId: number;
    memberAddress: string;
  }) => {
    const data = await kickMember(account, familyId, memberAddress);

    return data.transactionHash;
  }
);

export const updateFamilyLeaveFeeAction = createAsyncThunk(
  "family/updateFamilyLeaveFeeAction",
  async ({
    account,
    familyId,
    value,
  }: {
    account: string;
    familyId: number;
    value: number;
  }) => {
    const data = await updateFamilyLeaveFee(account, familyId, value);

    return data.transactionHash;
  }
);

export const createAllegianceAction = createAsyncThunk(
  "family/createAllegianceAction",
  async ({ familyId, account }: { familyId: number; account: string }) => {
    const data = await createAllegiance(familyId, account);

    return data.transactionHash;
  }
);

//#endregion

//#region Sign message
export const signFamilyCreateAction = createAsyncThunk(
  "family/signFamily",
  async ({ signMsg }: { signMsg: any }) => {
    const signature = await signMessage(signMsg);

    return signature;
  }
);

export const signData = createAsyncThunk(
  "family/signData",
  async ({ signMsg }: { signMsg: any }) => {
    const signature = await signMessage(signMsg);

    return signature;
  }
);

//#endregion

export const inviteAllegianceAction = createAsyncThunk(
  "family/inviteAllegianceAction",
  async ({
    allegianceId,
    familyId,
    account,
  }: {
    allegianceId: number;
    familyId: number;
    account: string;
  }) => {
    const data = await inviteAllegianceFamily(allegianceId, familyId, account);

    return data.transactionHash;
  }
);

export const acceptAllegianceInvitationAction = createAsyncThunk(
  "family/acceptAllegianceInvitationAction",
  async ({
    invitationId,
    account,
  }: {
    invitationId: number;
    account: string;
  }) => {
    const data = await acceptAllegianceInvitation(invitationId, account);

    return data.transactionHash;
  }
);

export const rejectAllegianceInvitationAction = createAsyncThunk(
  "family/rejectAllegianceInvitationAction",
  async ({
    invitationId,
    account,
  }: {
    invitationId: number;
    account: string;
  }) => {
    const data = await rejectAllegianceInvitation(invitationId, account);

    return data.transactionHash;
  }
);

export const kickAllegianceMemberAction = createAsyncThunk(
  "family/kickAllegianceMemberAction",
  async ({
    allegianceId,
    familyId,
    account,
  }: {
    allegianceId: number;
    familyId: number;
    account: string;
  }) => {
    const data = await kickAllegianceMember(allegianceId, familyId, account);

    return data.transactionHash;
  }
);

export const exitAllegianceAction = createAsyncThunk(
  "family/exitAllegianceAction",
  async ({
    allegianceId,
    familyId,
    account,
  }: {
    allegianceId: number;
    familyId: number;
    account: string;
  }) => {
    const data = await exitAllegiance(allegianceId, familyId, account);

    return data.transactionHash;
  }
);

export const registerAllegianceAction = createAsyncThunk(
  "family/registerAllegianceAction",
  async ({ signature, signMsg }: { signature: string; signMsg: any }) => {
    const response = await axios.post(`${SERVER_URL}/allegiance`, {
      signature,
      signMsg,
    });

    return response.data.name;
  }
);

export const requestToJoinAllegianceAction = createAsyncThunk(
  "family/requestToJoinAllegianceAction",
  async ({
    allegianceId,
    account,
  }: {
    allegianceId: number;
    account: string;
  }) => {
    const data = await requestToJoinAllegiance(allegianceId, account);

    return data.transactionHash;
  }
);

export const acceptRequestAction = createAsyncThunk(
  "family/acceptRequestAction",
  async ({ requestId, account }: { requestId: number; account: string }) => {
    const data = await acceptRequest(requestId, account);

    return data.transactionHash;
  }
);

export const rejectRequestAction = createAsyncThunk(
  "family/rejectRequestAction",
  async ({ requestId, account }: { requestId: number; account: string }) => {
    const data = await rejectRequest(requestId, account);

    return data.transactionHash;
  }
);

export const familySlice = createSlice({
  name: "family",
  initialState,
  reducers: {
    setFamilyLeaveFee: (state, { payload }) => {
      state.familyDetail = {
        ...state.familyDetail,
        leaveFee: payload,
      };
    },

    setAllegianceDetail: (state, { payload }) => {
      state.allegianceDetail = {
        familyData: payload.familyData,
        allegianceData: parseFamilyAllegianceInfo(payload.allegianceData),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllegianceDetailById.pending, (state) => {});
    builder.addCase(
      getAllegianceDetailById.fulfilled,
      (state, { payload }) => {}
    );
    builder.addCase(getAllegianceDetailById.rejected, (state, { error }) => {});

    builder.addCase(getAllegianceList.pending, (state) => {
      state.isLoadingAllegianceList = true;
    });
    builder.addCase(getAllegianceList.fulfilled, (state, { payload }) => {
      state.isLoadingAllegianceList = false;

      state.allegianceList = payload.map((allegiance: any, index: number) => {
        return parseFamilyAllegianceInfo(allegiance);
      });
    });
    builder.addCase(getAllegianceList.rejected, (state, { error }) => {
      state.isLoadingAllegianceList = false;
    });

    builder.addCase(getFamilyList.pending, (state) => {
      state.isLoadingFamilyList = true;
    });
    builder.addCase(getFamilyList.fulfilled, (state, { payload }) => {
      state.isLoadingFamilyList = false;
      state.familyList = payload.map((family: any, index: number) => {
        return {
          ...family,
          allegiance: family.allegianceData
            ? parseFamilyAllegianceInfo(family.allegianceData)
            : null,
        } as FamilyInfo;
      });
    });
    builder.addCase(getFamilyList.rejected, (state, { error }) => {
      state.isLoadingFamilyList = false;
    });

    builder.addCase(getFamilyDetailByName.pending, (state) => {
      state.isLoadingFamilyDetail = true;
    });
    builder.addCase(getFamilyDetailByName.fulfilled, (state, { payload }) => {
      state.familyDetail = { ...state.familyDetail, ...payload };

      state.familyDetail = {
        ...payload,
        leaveFee: state.familyDetail.leaveFee,
        promotion: state.familyDetail.promotion,
        bankInfo: state.familyDetail.bankInfo,
        businessObjects: state.familyDetail.businessObjects,
        allegiance: payload.allegiance
          ? parseFamilyAllegianceInfo(payload.allegiance)
          : null,
      };
      state.isLoadingFamilyDetail = false;
    });
    builder.addCase(getFamilyDetailByName.rejected, (state, { error }) => {
      state.isLoadingFamilyDetail = false;
    });

    builder.addCase(getFamilyDetailById.pending, (state) => {});
    builder.addCase(getFamilyDetailById.fulfilled, (state, { payload }) => {});
    builder.addCase(getFamilyDetailById.rejected, (state, { error }) => {});

    builder.addCase(getFamilyInvitations.pending, () => {});
    builder.addCase(getFamilyInvitations.fulfilled, (state, { payload }) => {
      state.invitationList = payload;
    });
    builder.addCase(getFamilyInvitations.rejected, (state, { error }) => {});

    builder.addCase(getFamilyPromotion.pending, () => {});
    builder.addCase(getFamilyPromotion.fulfilled, (state, { payload }) => {
      state.familyDetail = {
        ...state.familyDetail,
        promotion: payload,
      };
    });
    builder.addCase(getFamilyPromotion.rejected, (state, { error }) => {
      state.familyDetail = {
        ...state.familyDetail,
        promotion: null,
      };
    });

    builder.addCase(getFamilyBankInfo.pending, (state) => {});
    builder.addCase(getFamilyBankInfo.fulfilled, (state, { payload }) => {
      state.familyDetail = {
        ...state.familyDetail,
        bankInfo: payload,
      };
    });
    builder.addCase(getFamilyBankInfo.rejected, (state, { error }) => {
      state.familyDetail = {
        ...state.familyDetail,
        bankInfo: null,
      };
    });

    builder.addCase(getFamilyBusinesses.pending, (state) => {});
    builder.addCase(getFamilyBusinesses.fulfilled, (state, { payload }) => {
      const familyBusiness: BusinessInfo[] = payload.map(
        (business: any, index: number) => {
          return {
            id: business.id,
            userId: business.user_id,
            itemId: business.item_id,
            city: business.city,
            owner: business.owner,
            itemDetail: {
              id: business.itemDetail.id,
              typeId: business.itemDetail.type_id,
              categoryId: business.itemDetail.category_id,
              categoryName: business.itemDetail.category_name,
              typeName: business.itemDetail.type_name,
              inventoryWorth: business.itemDetail.inventory_worth,
            },
          };
        }
      );

      state.familyDetail = {
        ...state.familyDetail,
        businessObjects: familyBusiness,
      };
    });
    builder.addCase(getFamilyBusinesses.rejected, (state, { error }) => {
      state.familyDetail = {
        ...state.familyDetail,
        businessObjects: null,
      };
    });

    builder.addCase(updateAllegianceSharingAction.pending, (state) => {
      state.isUpdatingAllegianceSharing = true;
    });
    builder.addCase(
      updateAllegianceSharingAction.fulfilled,
      (state, { payload }) => {
        state.isUpdatingAllegianceSharing = false;
      }
    );
    builder.addCase(
      updateAllegianceSharingAction.rejected,
      (state, { error }) => {
        state.isUpdatingAllegianceSharing = false;
      }
    );

    builder.addCase(updateAllegianceLogo.pending, (state) => {
      state.isUpdatingAllegianceLogo = true;
    });
    builder.addCase(updateAllegianceLogo.fulfilled, (state, { payload }) => {
      state.isUpdatingAllegianceLogo = false;
    });
    builder.addCase(updateAllegianceLogo.rejected, (state, { error }) => {
      state.isUpdatingAllegianceLogo = false;
    });

    builder.addCase(updateFamilyPromotionAction.pending, (state) => {
      state.isUpdatingFamilyPromotion = true;
    });
    builder.addCase(
      updateFamilyPromotionAction.fulfilled,
      (state, { payload }) => {
        state.isUpdatingFamilyPromotion = false;
      }
    );
    builder.addCase(
      updateFamilyPromotionAction.rejected,
      (state, { error }) => {
        state.isUpdatingFamilyPromotion = false;
      }
    );

    builder.addCase(updateFamilyBrandingAction.pending, (state) => {
      state.isUpdatingFamilyBranding = true;
    });

    builder.addCase(
      updateFamilyBrandingAction.fulfilled,
      (state, { payload }) => {
        state.isUpdatingFamilyBranding = false;
      }
    );
    builder.addCase(updateFamilyBrandingAction.rejected, (state, { error }) => {
      state.isUpdatingFamilyBranding = false;
    });

    builder.addCase(updateFamilySharingAction.pending, (state) => {
      state.isUpdatingFamilySharing = true;
    });

    builder.addCase(
      updateFamilySharingAction.fulfilled,
      (state, { payload }) => {
        state.isUpdatingFamilySharing = false;
      }
    );
    builder.addCase(updateFamilySharingAction.rejected, (state, { error }) => {
      state.isUpdatingFamilySharing = false;
    });

    builder.addCase(updateCapoRegimeAction.pending, (state) => {
      state.isUpdatingFamilyCapoRegime = true;
    });

    builder.addCase(updateCapoRegimeAction.fulfilled, (state, { payload }) => {
      state.isUpdatingFamilyCapoRegime = false;
    });
    builder.addCase(updateCapoRegimeAction.rejected, (state, { error }) => {
      state.isUpdatingFamilyCapoRegime = false;
    });

    builder.addCase(approveFamilyAction.pending, (state) => {
      state.isApprovingFamilyCreate = true;
      state.isCreatingFamily = true;
    });
    builder.addCase(approveFamilyAction.fulfilled, (state, { payload }) => {
      state.isApprovingFamilyCreate = false;
    });
    builder.addCase(approveFamilyAction.rejected, (state, { error }) => {
      state.isApprovingFamilyCreate = false;
      state.isCreatingFamily = false;
    });

    builder.addCase(createFamilyAction.pending, (state) => {
      state.isPerformingFamilyCreate = true;
      state.isCreatingFamily = true;
    });
    builder.addCase(createFamilyAction.fulfilled, (state, { payload }) => {
      state.isPerformingFamilyCreate = false;
    });
    builder.addCase(createFamilyAction.rejected, (state, { error }) => {
      state.isPerformingFamilyCreate = false;
      state.isCreatingFamily = false;
    });

    builder.addCase(signFamilyCreateAction.pending, (state) => {
      state.isCreatingFamily = true;
    });
    builder.addCase(
      signFamilyCreateAction.fulfilled,
      (state, { payload }) => {}
    );
    builder.addCase(signFamilyCreateAction.rejected, (state, { error }) => {
      state.isCreatingFamily = false;
    });

    builder.addCase(registerFamilyCreateAction.pending, (state) => {
      state.isCreatingFamily = true;
    });
    builder.addCase(
      registerFamilyCreateAction.fulfilled,
      (state, { payload }) => {
        state.isCreatingFamily = false;
      }
    );
    builder.addCase(registerFamilyCreateAction.rejected, (state, { error }) => {
      state.isCreatingFamily = false;
    });

    builder.addCase(inviteFamilyMemberAction.pending, (state) => {
      state.isInvitingFamilyMember = true;
    });
    builder.addCase(
      inviteFamilyMemberAction.fulfilled,
      (state, { payload }) => {
        state.isInvitingFamilyMember = false;
      }
    );
    builder.addCase(inviteFamilyMemberAction.rejected, (state, { error }) => {
      state.isInvitingFamilyMember = false;
    });

    builder.addCase(approveBankAction.pending, (state) => {
      state.isApprovingBankStaking = true;
      state.isCreatingStaking = true;
    });
    builder.addCase(approveBankAction.fulfilled, (state, { payload }) => {
      state.isApprovingBankStaking = false;
    });
    builder.addCase(approveBankAction.rejected, (state, { error }) => {
      state.isApprovingBankStaking = false;

      if (error.message !== WalletErrorMsg.Indexing)
        state.isCreatingStaking = false;
    });

    builder.addCase(createBankStakingAction.pending, (state) => {
      state.isPerformingStaking = true;
    });
    builder.addCase(createBankStakingAction.fulfilled, (state, { payload }) => {
      state.isPerformingStaking = false;
    });
    builder.addCase(createBankStakingAction.rejected, (state, { error }) => {
      state.isPerformingStaking = false;
      state.isCreatingStaking = false;
    });

    builder.addCase(withdrawBankStakingAction.pending, (state) => {
      state.isWithdrawingStaking = true;
    });
    builder.addCase(
      withdrawBankStakingAction.fulfilled,
      (state, { payload }) => {
        state.isWithdrawingStaking = false;
      }
    );
    builder.addCase(withdrawBankStakingAction.rejected, (state, { error }) => {
      state.isWithdrawingStaking = false;
    });

    builder.addCase(updateFamilyLeaderAction.pending, (state) => {
      state.isUpdatingLeader = true;
    });
    builder.addCase(
      updateFamilyLeaderAction.fulfilled,
      (state, { payload }) => {
        state.isUpdatingLeader = false;
      }
    );
    builder.addCase(updateFamilyLeaderAction.rejected, (state, { error }) => {
      state.isUpdatingLeader = false;
    });

    builder.addCase(updateFamilySuccessorAction.pending, (state) => {
      state.isUpdatingLeader = true;
    });
    builder.addCase(
      updateFamilySuccessorAction.fulfilled,
      (state, { payload }) => {
        state.isUpdatingLeader = false;
      }
    );
    builder.addCase(
      updateFamilySuccessorAction.rejected,
      (state, { error }) => {
        state.isUpdatingLeader = false;
      }
    );

    builder.addCase(acceptFamilyInvitationAction.pending, (state) => {
      state.isAcceptingInvitation = true;
    });
    builder.addCase(
      acceptFamilyInvitationAction.fulfilled,
      (state, { payload }) => {
        state.isAcceptingInvitation = false;
      }
    );
    builder.addCase(
      acceptFamilyInvitationAction.rejected,
      (state, { error }) => {
        state.isAcceptingInvitation = false;
      }
    );

    builder.addCase(cancelFamilyInvitationAction.pending, (state) => {
      state.isCancelingInvitation = true;
    });
    builder.addCase(
      cancelFamilyInvitationAction.fulfilled,
      (state, { payload }) => {
        state.isCancelingInvitation = false;
      }
    );
    builder.addCase(
      cancelFamilyInvitationAction.rejected,
      (state, { error }) => {
        state.isCancelingInvitation = false;
      }
    );

    builder.addCase(rejectFamilyInvitationAction.pending, (state) => {
      state.isRejectingInvitation = true;
    });
    builder.addCase(
      rejectFamilyInvitationAction.fulfilled,
      (state, { payload }) => {
        state.isRejectingInvitation = false;
      }
    );
    builder.addCase(
      rejectFamilyInvitationAction.rejected,
      (state, { error }) => {
        state.isRejectingInvitation = false;
      }
    );

    builder.addCase(leaveFamilyAction.pending, (state) => {
      state.isLeavingFamily = true;
    });
    builder.addCase(leaveFamilyAction.fulfilled, (state, { payload }) => {
      state.isLeavingFamily = false;
    });
    builder.addCase(leaveFamilyAction.rejected, (state, { error }) => {
      state.isLeavingFamily = false;
    });

    builder.addCase(kickMemberAction.pending, (state) => {
      state.isKickingMember = true;
    });
    builder.addCase(kickMemberAction.fulfilled, (state, { payload }) => {
      state.isKickingMember = false;
    });
    builder.addCase(kickMemberAction.rejected, (state, { error }) => {
      state.isKickingMember = false;
    });

    builder.addCase(updateFamilyLeaveFeeAction.pending, (state) => {
      state.isUpdatingFamilyLeaveFee = true;
    });
    builder.addCase(
      updateFamilyLeaveFeeAction.fulfilled,
      (state, { payload }) => {
        state.isUpdatingFamilyLeaveFee = false;
      }
    );
    builder.addCase(updateFamilyLeaveFeeAction.rejected, (state, { error }) => {
      state.isUpdatingFamilyLeaveFee = false;
    });

    builder.addCase(signData.pending, (state) => {
      state.isSigningData = true;
    });
    builder.addCase(signData.fulfilled, (state, { payload }) => {
      state.isSigningData = false;
    });
    builder.addCase(signData.rejected, (state, { error }) => {
      state.isSigningData = false;
    });

    builder.addCase(createAllegianceAction.pending, (state) => {
      state.isCreatingAllegiance = true;
    });
    builder.addCase(createAllegianceAction.fulfilled, (state, { payload }) => {
      state.isCreatingAllegiance = false;
    });
    builder.addCase(createAllegianceAction.rejected, (state, { error }) => {
      state.isCreatingAllegiance = false;
    });

    builder.addCase(registerAllegianceAction.pending, (state) => {
      state.isRegisteringAllegiance = true;
    });
    builder.addCase(
      registerAllegianceAction.fulfilled,
      (state, { payload }) => {
        state.isRegisteringAllegiance = false;
      }
    );
    builder.addCase(registerAllegianceAction.rejected, (state, { error }) => {
      state.isRegisteringAllegiance = false;
    });

    builder.addCase(inviteAllegianceAction.pending, (state) => {
      state.isInvitingAllegiance = true;
    });
    builder.addCase(inviteAllegianceAction.fulfilled, (state, { payload }) => {
      state.isInvitingAllegiance = false;
    });
    builder.addCase(inviteAllegianceAction.rejected, (state, { error }) => {
      state.isInvitingAllegiance = false;
    });

    builder.addCase(requestToJoinAllegianceAction.pending, (state) => {
      state.isRequestingToJoinAllegiance = true;
    });
    builder.addCase(
      requestToJoinAllegianceAction.fulfilled,
      (state, { payload }) => {
        state.isRequestingToJoinAllegiance = false;
      }
    );
    builder.addCase(
      requestToJoinAllegianceAction.rejected,
      (state, { error }) => {
        state.isRequestingToJoinAllegiance = false;
      }
    );

    builder.addCase(acceptAllegianceInvitationAction.pending, (state) => {
      state.isAcceptingAllegiance = true;
    });
    builder.addCase(
      acceptAllegianceInvitationAction.fulfilled,
      (state, { payload }) => {
        state.isAcceptingAllegiance = false;
      }
    );
    builder.addCase(
      acceptAllegianceInvitationAction.rejected,
      (state, { error }) => {
        state.isAcceptingAllegiance = false;
      }
    );

    builder.addCase(rejectAllegianceInvitationAction.pending, (state) => {
      state.isRejectingAllegiance = true;
    });
    builder.addCase(
      rejectAllegianceInvitationAction.fulfilled,
      (state, { payload }) => {
        state.isRejectingAllegiance = false;
      }
    );
    builder.addCase(
      rejectAllegianceInvitationAction.rejected,
      (state, { error }) => {
        state.isRejectingAllegiance = false;
      }
    );

    builder.addCase(kickAllegianceMemberAction.pending, (state) => {
      state.isKickingAllegiance = true;
    });
    builder.addCase(
      kickAllegianceMemberAction.fulfilled,
      (state, { payload }) => {
        state.isKickingAllegiance = false;
      }
    );
    builder.addCase(kickAllegianceMemberAction.rejected, (state, { error }) => {
      state.isKickingAllegiance = false;
    });

    builder.addCase(exitAllegianceAction.pending, (state) => {
      state.isExitingAllegiance = true;
    });
    builder.addCase(exitAllegianceAction.fulfilled, (state, { payload }) => {
      state.isExitingAllegiance = false;
    });
    builder.addCase(exitAllegianceAction.rejected, (state, { error }) => {
      state.isExitingAllegiance = false;
    });

    builder.addCase(acceptRequestAction.pending, (state) => {
      state.isAcceptingAllegianceRequest = true;
    });
    builder.addCase(acceptRequestAction.fulfilled, (state, { payload }) => {
      state.isAcceptingAllegianceRequest = false;
    });
    builder.addCase(acceptRequestAction.rejected, (state, { error }) => {
      state.isAcceptingAllegianceRequest = false;
    });

    builder.addCase(rejectRequestAction.pending, (state) => {
      state.isRejectingAllegianceRequest = true;
    });
    builder.addCase(rejectRequestAction.fulfilled, (state, { payload }) => {
      state.isRejectingAllegianceRequest = false;
    });
    builder.addCase(rejectRequestAction.rejected, (state, { error }) => {
      state.isRejectingAllegianceRequest = false;
    });
  },
});

export const { setFamilyLeaveFee, setAllegianceDetail } = familySlice.actions;

export default familySlice.reducer;
