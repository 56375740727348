import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: any) => ({
  body: {
    background: "url(/assets/imgs/map/bg.png) no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center bottom",
    maskImage:
      "linear-gradient(to bottom, transparent 0%, black 10%, black 60%, transparent 100%)",
    minHeight: 900,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Philosopher",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "auto 100%",
      backgroundPosition: "center",
      minHeight: "500px",
    },
  },

  comingsoon: {
    minWidth: 720,
    minHeight: 350,
    backgroundColor: "rgba(0,0,0,0.65)",
    borderRadius: 32,
    padding: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backdropFilter: "blur(10px)",

    [theme.breakpoints.down("md")]: {
      minHeight: 200,
      minWidth: 320,
      width: "90%",
      padding: "20px 0",
    },
  },

  mapLogoSection: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    color: "white",
    fontSize: 28,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },

  mapLogo: {
    width: 48,
  },

  desSection: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    color: "white",
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
      gap: 12,
    },
  },

  comingSoonText: {
    fontSize: 60,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 28,
    },
  },

  description: {
    fontSize: 24,
    color: "#888888",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
}));

export default styles;
