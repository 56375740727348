const configPulseChain = {
  mafiaAddress: "0xa27aDe5806Ded801b93499C6fA23cc8dC9AC55EA",
  pairAddress: "0x113bbdfea64b06aebe14a50e00c70149a32973ab",
  vaultAddress: "0x776F3D2d483580b12a2cD163bE40F649b2d1FF34",
  vault1Address: "0xCABDd520EeDC6736F4C057Cba275673d74766A6b",
  profileTicketAddress: "0x7FB6A056877c1da14a63bFECdE95ebbFa854f07F",

  mafiaFamilyAddress: "0x3363cf983ae23AF2D95a81bA4A39C36084f8BEc4",
  familyBankAddress: "0xaD068Df1cedBf631A9D9B050eEBf1778E2d4A2ED",
  ogCrateAddress: "0x3325E42aA71188939216b669E8d431718e5bd790",
  crateMinterAddress: "0x7FE7220E6A8AAB508c60be9d48fEfacDbe6BC179",
  inventoryAddress: "0x2c60de22Ec20CcE72245311579c4aD9e5394Adc4",
  marketAddress: "0x321e27aaB7e6F5DE221AE3eAe63306345f3A465d",
  ogNFTAddress: "0xC647421A47dc793292E880bD77782c664BD6Af2a",
  ogPremiumAddress: "0xf0ae11A736b90987589ce64b1D77eBc328d0622F",
  familyAllegianceAddress: "0x32d3C91A1c67f4ec9Bd688D72030b4127d94b9eC",

  gamebankAddress: "0x839340bDC0b0E4449b7e1dEBD0db7E93861Ed1D9",
  exchangeAddress: "0x11ee2732eD4C6BFe673e7b4BE15ece35D6a8cCD7",
  depositAddress: "0xC9565b4f23C301Cf9f158D72A842BA6a53B84590",
  vaultSplitterAddress: "0x5853EDBe3E619C73f9720ce4aF50F506340617B0",

  mapAddress: "0x43b0D804dB511F6f68952f35b0D04BeD01581F06", //FIXME: Update contract address

  chainSymbolFull: "Pulse",
  chainSymbol: "PLS",
  chainName: "PulseChain",
  chainId: 369,
  chainIdHex: 0x171,
  blockTime: 3000,

  providerList: ["https://rpc.pulsechain.com"],

  explorerUrl: "https://otter.pulsechain.com/",

  nativePriceUrl:
    "https://api.coingecko.com/api/v3/simple/price?ids=pulsechain&vs_currencies=usd",
  mafiaPriceUrl: "https://api.dexscreener.com/latest/dex/pairs/pulsechain/",

  domain: "pulsemafia.io",
  title: "PulseMafia.io",
  gitbookUrl: "https://mafiagame.gitbook.io/",

  serverEndpoint: "https://backend.pulsemafia.io",
  // serverEndpoint: "http://localhost:3000",
  mockServerEndpoint: "http://localhost:5000",
  testServerEndpoint: "https://54q31jdb-3000.usw3.devtunnels.ms",

  newFirebaseConfig: {
    apiKey: "AIzaSyDCe5-P3COibgJSlCTQUITW3vKcrG9T15g",
    authDomain: "pls-mafia.firebaseapp.com",
    projectId: "pls-mafia",
    storageBucket: "pls-mafia.appspot.com",
    messagingSenderId: "512691599171",
    appId: "1:512691599171:web:a578623ab71affd42221f6",
    measurementId: "G-XLJ2BSVPDD",
  },

  storageBucket: "gs://pls-mafia.appspot.com",

  symbol: "$MAFIA",
};

export default configPulseChain;
