import {
  Box,
  Button,
  Divider,
  Slider,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import useStyles from "./index.styles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import moneyIcon from "../../assets/imgs/exchange/money.png";
import Logo from "../Logo";
import React, { useCallback, useEffect, useState } from "react";
import { toastError, toastSuccess, toUSDFormat } from "../../utils/utils";
import useWallet from "../../hook/useWallet";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { dispatchTxAction } from "../../helper/dispatchTxAction";
import {
  addLiquidityTicketAction,
  approveInGameCashToDepositTicketAction,
  cancelLiquidity,
  getAvailablePrice,
  getCashTotalSupply,
  getChartHistory,
  getRatioData,
  getWithdrawStat,
  withdrawMafia,
} from "../../reducers/exchange.slice";
import { Errors } from "../../constants/errors";
import { Messages } from "../../constants/messages";
import useGameBankBalance from "../../hook/useGameBankBalance";
import useMafiaPrice from "../../hook/useMafiaPrice";
import WithdrawStat from "./WithdrawStat";
import { StatType } from "../../constants/enum/deposit";
import DepositChart from "./DepositChart";

const PositionInfo = ({
  position,
  cashPerMafia,
  mafiaPrice,
  cash,
}: {
  position: number | null;
  cashPerMafia: string;
  mafiaPrice: number;
  cash: number;
}) => {
  return (
    <Box>
      <Box marginTop={4} sx={{ opacity: 0.5 }}>
        Position information
      </Box>
      <Divider sx={{ backgroundColor: "white", marginTop: 2, opacity: 0.2 }} />
      <Box display={"flex"} justifyContent={"space-between"} marginTop={2}>
        <Box>Liquidity position</Box>
        <Box>{position ? position : ""}</Box>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} marginTop={0.5}>
        <Box>Estimated value</Box>
        <Box>${toUSDFormat((cash / Number(cashPerMafia)) * mafiaPrice)}</Box>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} marginTop={0.5}>
        <Box>Expected $MAFIA</Box>
        <Box>{toUSDFormat(cash / Number(cashPerMafia))}</Box>
      </Box>
    </Box>
  );
};

const Withdraw = () => {
  const { classes } = useStyles();
  const theme = useTheme<Theme>();
  const [visiblePosiInfo, setVisiblePosiInfo] = useState(false);

  const [cashPerMafia, setCashPerMafia] = useState<string>("100");
  const [cash, setCash] = useState(0);
  const [position, setPosition] = useState<number | null>(null);
  const [statType, setStatType] = useState<StatType>(StatType.AllPosition);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);

  const { signMsg, signature } = useAppSelector((state) => state.auth);
  const { account, connectWallet } = useWallet();
  const { updateBalance } = useGameBankBalance(account, signMsg, signature);
  const mafiaPrice = useMafiaPrice();
  const dispatch = useAppDispatch();

  const { cashBalance } = useAppSelector((state) => state.profile);
  const { avgCashPerMafia, activeLPs, chartHistory } = useAppSelector(
    (state) => state.exchange
  );
  const { myProfile } = useAppSelector((state) => state.profile);

  const handleCashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, "");
    if (!isNaN(Number(value))) {
      setCash(Number(value));
    }
  };

  const handleChangeCashPerMafia = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCashPerMafia(e.target.value);
    },
    []
  );

  const handleToggleMoreBut = () => {
    setVisiblePosiInfo((status) => !status);
  };

  const getStatdata = useCallback(
    ({
      statType,
      account,
      pageIndex,
      perPage,
    }: {
      statType: StatType;
      account: string;
      pageIndex: number;
      perPage: number;
    }) => {
      dispatch(getRatioData());
      if (statType !== StatType.ViewAllBuyOrders) {
        dispatch(getWithdrawStat({ statType, account, pageIndex, perPage }));
      } else {
        dispatch(getChartHistory());
      }
    },
    [dispatch]
  );

  const totalRemainCash = useCallback(() => {
    let usd = 0;
    const liquidityCash = activeLPs.reduce((acc, item) => {
      usd += (item.cashAmount / item.cashPerMafia) * mafiaPrice;
      return acc + item.cashAmount;
    }, 0);

    return { liquidityCash, usd };
  }, [activeLPs, mafiaPrice]);

  const handleConfirmPosition = useCallback(() => {
    if (!account) {
      return connectWallet();
    }
    if (!cash) {
      return toastError(Errors.EXCHANGE.DEPOSIT.INPUT_CASH);
    }
    if (cashBalance < cash) {
      return toastError(Errors.EXCHANGE.DEPOSIT.Cash_MORE_DEPOSIT);
    }
    dispatchTxAction(
      dispatch,
      approveInGameCashToDepositTicketAction({
        account,
        cash,
        cashPerMafia: Number(cashPerMafia),
      }),
      async () => {
        try {
          await dispatch(
            addLiquidityTicketAction({
              account,
              cash,
              cashPerMafia: Number(cashPerMafia),
            })
          ).unwrap();
          toastSuccess(Messages.EXCHANGE.DEPOSIT.ADDED_NEW_LP);
          setCash(0);
          getStatdata({ statType, account, pageIndex: page - 1, perPage });
        } catch (err) {
          console.error(err);
          toastError(Errors.EXCHANGE.DEPOSIT.FAILED_ADD_LIQUIDITY);
        } finally {
          updateBalance(account, signMsg, signature);
        }
      },
      3000
    );
  }, [
    cashBalance,
    cash,
    dispatch,
    account,
    cashPerMafia,
    signMsg,
    signature,
    updateBalance,
    statType,
    page,
    perPage,
    connectWallet,
    getStatdata,
  ]);

  const handleCancelLiquidity = useCallback(
    async (id: number) => {
      try {
        await dispatch(cancelLiquidity({ id, account })).unwrap();
        getStatdata({ statType, account, pageIndex: page - 1, perPage });
        toastSuccess(Messages.EXCHANGE.DEPOSIT.CANCELED_SUCCEED);
      } catch (err) {
        console.error(err);
        toastError(Errors.EXCHANGE.DEPOSIT.FAILED_REMOVE_LIQUIDITY);
      }
    },
    [dispatch, account, page, perPage, statType, getStatdata]
  );

  const handleWithdrawMafia = useCallback(
    async (id: number) => {
      try {
        await dispatch(withdrawMafia({ id, account })).unwrap();
        getStatdata({ statType, account, pageIndex: page - 1, perPage });
        toastSuccess(Messages.EXCHANGE.DEPOSIT.WITHDRAWN_SUCCEED);
      } catch (err) {
        console.error(err);
        toastError(Errors.EXCHANGE.DEPOSIT.FAILED_REMOVE_LIQUIDITY);
      }
    },
    [dispatch, account, page, perPage, statType, getStatdata]
  );

  const mafiaBidsForOneDay = useCallback(() => {
    const currentTime = new Date().getTime() / 1000;
    const fromTime = currentTime - 24 * 3600;
    let usd = 0;
    const amount = chartHistory.reduce((acc, item) => {
      if (item.timestamp > fromTime) {
        usd += item.priceUSD * item.cashReceived;
        return acc + item.mafiaSpent;
      }
      return 0;
    }, 0);
    return { amount, usd };
  }, [chartHistory]);

  useEffect(() => {
    updateBalance(account, signMsg, signature);
  }, [dispatch, account, signMsg, signature, updateBalance]);

  useEffect(() => {
    getStatdata({ statType, account, pageIndex: page - 1, perPage });
  }, [dispatch, statType, account, page, perPage, getStatdata]);

  useEffect(() => {
    setCashPerMafia(avgCashPerMafia.toString());
  }, [avgCashPerMafia]);

  useEffect(() => {
    const rank =
      activeLPs.filter((data) => data.cashPerMafia > Number(cashPerMafia))
        .length + 1;
    setPosition(rank);
  }, [cashPerMafia, activeLPs]);

  useEffect(() => {
    dispatch(getChartHistory());
    dispatch(getAvailablePrice());
    dispatch(getCashTotalSupply());
  }, [dispatch]);

  useEffect(() => {
    setPage(1);
  }, [statType]);

  return (
    <>
      <Box className={classes.depositInfo}>
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Box
            className={classes.depositCard}
            sx={{ paddingRight: "0!important" }}
          >
            <DepositChart visiblePosiInfo={visiblePosiInfo} />
          </Box>
          <Box className={classes.chartBottomInfo}>
            <Box>
              <Box
                component={"img"}
                src={moneyIcon}
                sx={{ width: "24px" }}
              ></Box>
              <Box component={"span"}>
                Total liquidity:{" "}
                {toUSDFormat(totalRemainCash().liquidityCash, 0)} ($
                {toUSDFormat(totalRemainCash().usd, 0)})
              </Box>
            </Box>
            <Box>
              <Logo sx={{ width: "24px" }} />
              <Box component={"span"}>
                24h bids: {toUSDFormat(mafiaBidsForOneDay().amount)} MAFIA ($
                {toUSDFormat(mafiaBidsForOneDay().usd)})
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.depositCard}>
          <Typography
            fontFamily={"Philosopher"}
            color={"white"}
            textAlign={"center"}
            fontSize={24}
            fontWeight={700}
          >
            New liquidity position
          </Typography>
          <Typography
            fontFamily={"Philosopher"}
            color="white"
            textAlign="center"
            fontSize={16}
            marginTop={4}
            sx={{ opacity: 0.8 }}
          >
            24h average ratio
          </Typography>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={2}
            alignItems={"center"}
          >
            <Box className={classes.priceUpLabel}>
              <Box>Price up</Box>
              <Box>
                <ArrowDropUpIcon />
              </Box>
            </Box>
            <Box className={classes.ratioTextBox}>
              1 :{" "}
              <TextField
                className={classes.cashPerMafiaInput}
                value={cashPerMafia}
                onChange={handleChangeCashPerMafia}
              />
            </Box>
            <Box className={classes.priceDownLabel}>
              <Box>Price down</Box>
              <Box>
                <ArrowDropDownIcon />
              </Box>
            </Box>
          </Box>
          <Slider
            size="medium"
            valueLabelDisplay="auto"
            value={Number(cashPerMafia)}
            onChange={(e, newValue) => setCashPerMafia(newValue.toString())}
            min={Math.floor(avgCashPerMafia - (avgCashPerMafia * 50) / 100)}
            max={Math.floor(avgCashPerMafia + (avgCashPerMafia * 50) / 100)}
            sx={{
              "& .MuiSlider-track": {
                backgroundColor: "#a4a4a4",
                opacity: 1,
                border: "none",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#a4a4a4",
                opacity: 1,
              },
            }}
          />

          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={4}
            alignItems={"center"}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Box component={"img"} src={moneyIcon} width={24}></Box>
              <Typography color={"white"} fontFamily={"Philosopher"}>
                Enter cash amount
              </Typography>
            </Box>
            <TextField
              value={cash > 0 ? Intl.NumberFormat().format(cash) : ""}
              className={classes.cashInput}
              onChange={handleCashChange}
            />
          </Box>

          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            marginTop={2}
            alignItems={"center"}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Logo sx={{ width: "24px" }} />
              <Typography color={"white"} fontFamily={"Philosopher"}>
                Expected $MAFIA
              </Typography>
            </Box>
            <Box>
              {toUSDFormat(cash / Number(cashPerMafia))}{" "}
              <Box component={"span"} sx={{ opacity: 0.4 }}>
                ($
                {toUSDFormat((cash / Number(cashPerMafia)) * mafiaPrice)})
              </Box>
            </Box>
          </Box>

          {visiblePosiInfo && (
            <PositionInfo
              cash={cash}
              mafiaPrice={mafiaPrice}
              position={position}
              cashPerMafia={cashPerMafia}
            />
          )}

          <Box
            display={"flex"}
            position={"relative"}
            alignItems={"center"}
            marginTop={4}
          >
            <Box display={"flex"} justifyContent={"center"} width={"100%"}>
              <Button
                className={classes.confirm}
                onClick={handleConfirmPosition}
                disabled={myProfile.name ? false : true}
              >
                Confirm position
              </Button>
            </Box>
            <Box className={classes.moreButton} onClick={handleToggleMoreBut}>
              {visiblePosiInfo ? "Less" : "More"}
            </Box>
          </Box>
        </Box>
      </Box>

      <WithdrawStat
        statType={statType}
        setStatType={setStatType}
        page={page}
        perPage={perPage}
        setPage={setPage}
        handleCancelLiquidity={handleCancelLiquidity}
        handleWithdrawMafia={handleWithdrawMafia}
      />
    </>
  );
};
export default Withdraw;
