import { Box } from "@mui/material";

import mapLogo from "../../assets/imgs/map/maps.png";

import useCurrentTime from "../../hook/useCurrentTime";

import { convertDateTime } from "../../utils/utils";
import useStyles from "./index.styles";

const ReleaseDate = new Date("2024-12-23T00:00:00.000Z");

const Map = () => {
  const { classes } = useStyles();
  const currentTime = useCurrentTime();

  return (
    <Box>
      <Box className={classes.body}>
        <Box className={classes.comingsoon}>
          <Box className={classes.mapLogoSection}>
            <Box
              component={"img"}
              className={classes.mapLogo}
              src={mapLogo}
            ></Box>
            The Map
          </Box>
          <Box className={classes.desSection}>
            <Box className={classes.comingSoonText}>
              {convertDateTime(ReleaseDate.getTime() / 1000 - currentTime)} Left
            </Box>
            <Box className={classes.description}>
              Get ready to build your empire
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Map;
